const fonts = [
  "Abhaya Libre",
  "Abril Fatface",
  "Acme",
  "Actor",
  "Advent Pro",
  "Aileron",
  "Alatsi",
  "Alfa Slab One",
  "Alice",
  "Alike",
  "Amaranth",
  "Amatic SC",
  "Amiko",
  "Amiri",
  "Amita",
  "Anaheim",
  "Andada",
  "Andika",
  "Angelecia Pro",
  "Antic",
  "Archivo",
  "Archivo Black",
  "Aref Ruqaa",
  "Arial",
  "Asap",
  "Asar",
  "Asul",
  "Athiti",
  "Atma",
  "Aubrey",
  "Averia Gruesa Libre",
  "Averia Libre",
  "Averia Sans Libre",
  "Averia Serif Libre",
  "B612",
  "B612 Mono",
  "Bad Script",
  "Balsamiq Sans",
  "Barlow",
  "Barriecito",
  "Basic",
  "Baumans",
  "Bebas Neue",
  "Belleza",
  "BenchNine",
  "Bentham",
  "Be Vietnam",
  "Big Shoulders Display",
  "Big Shoulders Inline Display",
  "Big Shoulders Inline Text",
  "Big Shoulders Stencil Display",
  "Big Shoulders Stencil Text",
  "Big Shoulders Text",
  "BioRhyme",
  "BioRhyme Expanded",
  "Biryani",
  "Bitter",
  "Black Ops One",
  "Bree Serif",
  "Bungee",
  "Cabin",
  "Cabin Condensed",
  "Cabin Sketch",
  "Caesar Dressing",
  "Cagliostro",
  "Calligraffitti",
  "Cambo",
  "Cardo",
  "Castoro",
  "Catamaran",
  "Caveat",
  "Caveat Brush",
  "Changa",
  "Changa One",
  "Chango",
  "Chathura",
  "Chau Philomene One",
  "Chela One",
  "Chelsea Market",
  "Chenla",
  "Chicle",
  "Chivo",
  "Cinzel",
  "Cinzel Decorative",
  "Clacon",
  "Clear Sans",
  "Clicker Script",
  "Codystar",
  "Comfortaa",
  "Comic Neue",
  "Cormorant",
  "Cormorant Garamond",
  "Cormorant Infant",
  "Cormorant SC",
  "Cormorant Unicase",
  "Corben",
  "Cousine",
  "Coustard",
  "Creepster",
  "Crimson Text",
  "Cutive",
  "Cutive Mono",
  "Damion",
  "Dancing Script",
  "David Libre",
  "Dawning of a New Day",
  "Days One",
  "Delius",
  "Delius Swash Caps",
  "Della Respira",
  "Dhyana",
  "Didact Gothic",
  "Diplomata",
  "Diplomata SC",
  "Domine",
  "Donegal One",
  "Doppio One",
  "Dosis",
  "Dr Sugiyama",
  "Droid Sans",
  "Droid Sans Mono",
  "Droid Serif",
  "Dufay",
  "Duru Sans",
  "Dynalight",
  "EBGaramond",
  "Eagle Lake",
  "East Sea Dokdo",
  "Eater",
  "Economica",
  "Ek Mukta",
  "Electrolize",
  "El Messiri",
  "Englebert",
  "Enriqueta",
  "Ericssonia",
  "Esteban",
  "Euphoria Script",
  "Fanwood Text",
  "Farro",
  "Fasthand",
  "Faustina",
  "Federant",
  "Federo",
  "Felipa",
  "Fira Code",
  "Fira Mono",
  "Fira Sans",
  "Fira Sans Condensed",
  "Fira Sans Extra Condensed",
  "Fjalla One",
  "Flamenco",
  "Flavors",
  "Fondamento",
  "Fontdiner Swanky",
  "Forum",
  "Franca",
  "Francois One",
  "Frank Ruhl Libre",
  "Freckle Face",
  "Fredericka the Great",
  "Fredoka One",
  "Freehand",
  "Fresca",
  "Frijole",
  "Fugaz One",
  "Galada",
  "Galdeano",
  "Galindo",
  "Gentium Basic",
  "Gentium Book Basic",
  "Geo",
  "Geostar",
  "Geostar Fill",
  "Germania One",
  "Gidugu",
  "Gilda Display",
  "Give You Glory",
  "Glass Antiqua",
  "Glegoo",
  "Gloria Hallelujah",
  "Goblin One",
  "Gochi Hand",
  "Gorditas",
  "Gothic A1",
  "Goudy Bookletter 1911",
  "Graduate",
  "Grand Hotel",
  "Great Vibes",
  "Grenze Gotisch",
  "Gruppo",
  "Gudea",
  "Hanalei",
  "Hanalei Fill",
  "Handlee",
  "Hanuman",
  "Happy Monkey",
  "Harmattan",
  "Headland One",
  "Heebo",
  "Henny Penny",
  "Hepta Slab",
  "Herr Von Muellerhoff",
  "Hi Melody",
  "Hind",
  "Hind Guntur",
  "Hind Madurai",
  "Hind Siliguri",
  "Hind Vadodara",
  "Holtwood One SC",
  "Homemade Apple",
  "Homenaje",
  "IBM Plex Mono",
  "IBM Plex Sans",
  "IBM Plex Sans Condensed",
  "IBM Plex Serif",
  "IM Fell DW Pica",
  "IM Fell DW Pica SC",
  "IM Fell Double Pica",
  "IM Fell Double Pica SC",
  "IM Fell English",
  "IM Fell English SC",
  "IM Fell French Canon",
  "IM Fell French Canon SC",
  "IM Fell Great Primer",
  "IM Fell Great Primer SC",
  "Iceberg",
  "Iceland",
  "Imprima",
  "Inconsolata",
  "Inder",
  "Indie Flower",
  "Inika",
  "Inknut Antiqua",
  "Irish Grover",
  "Istok Web",
  "Italiana",
  "Italianno",
  "Itim",
  "Jacques Francois",
  "Jacques Francois Shadow",
  "Jaldi",
  "Jim Nightshade",
  "Jockey One",
  "Jolly Lodger",
  "Jomhuria",
  "Jomolhari",
  "Josefin Sans",
  "Josefin Slab",
  "Joti One",
  "Jua",
  "Judson",
  "Julee",
  "Julius Sans One",
  "Jura",
  "Just Another Hand",
  "Just Me Again Down Here",
  "K2D",
  "Kadwa",
  "Kalam",
  "Kameron",
  "Kanit",
  "Kantumruy",
  "Karla",
  "Karma",
  "Katibeh",
  "Kaushan Script",
  "Kavivanar",
  "Kavoon",
  "Kdam Thmor",
  "Keania One",
  "Kelly Slab",
  "Kenia",
  "Khand",
  "Khmer",
  "Kite One",
  "Knewave",
  "KoHo",
  "Kodchasan",
  "Kosugi",
  "Kosugi Maru",
  "Kotta One",
  "Koulen",
  "Krub",
  "Kulim Park",
  "Kumar One",
  "Kumar One Outline",
  "Kurale",
  "La Belle Aurore",
  "Lacquer",
  "Laila",
  "Lakki Reddy",
  "Lalezar",
  "Lancelot",
  "Langar",
  "Lateef",
  "Lato",
  "League Script",
  "Leckerli One",
  "Ledger",
  "Lekton",
  "Lemon",
  "Lemonada",
  "Lexend",
  "Lexend Deca",
  "Lexend Exa",
  "Lexend Giga",
  "Lexend Mega",
  "Lexend Peta",
  "Lexend Tera",
  "Lexend Zetta",
  "Libre Barcode 128",
  "Libre Barcode 128 Text",
  "Libre Barcode 39",
  "Libre Barcode 39 Extended",
  "Libre Barcode 39 Extended Text",
  "Libre Barcode 39 Text",
  "Libre Baskerville",
  "Libre Caslon Display",
  "Libre Caslon Text",
  "Libre Franklin",
  "Life Savers",
  "Lilita One",
  "Lily Script One",
  "Limelight",
  "Limo",
  "Linden Hill",
  "Literata",
  "Lobster",
  "Lobster Two",
  "Londrina Outline",
  "Londrina Shadow",
  "Londrina Sketch",
  "Londrina Solid",
  "Long Cang",
  "Longdon Decorative",
  "Longdon Hallmark",
  "Longdon Print",
  "Longdon Swirl",
  "Longdon Victory",
  "Lookah Samara",
  "Lora",
  "Love Ya Like A Sister",
  "Loved by the King",
  "Lovers Quarrel",
  "Luckiest Guy",
  "Lusitana",
  "Lustria",
  "Luthier",
  "Macondo",
  "Macondo Swash Caps",
  "Mada",
  "Magra",
  "Maiden Orange",
  "Maitree",
  "Major Mono Display",
  "Mako",
  "Mali",
  "Mallanna",
  "Mame",
  "Mamey",
  "Mandali",
  "Mansalva",
  "Manuale",
  "Marcellus",
  "Marcellus SC",
  "Marck Script",
  "Margarine",
  "Margot",
  "Marianne",
  "Markazi Text",
  "Marko One",
  "Marmelad",
  "Martel",
  "Martel Sans",
  "Marvel",
  "Mate",
  "Mate SC",
  "Maven Pro",
  "McLaren",
  "Meddon",
  "Medula One",
  "Meera Inimai",
  "Megrim",
  "Meie Script",
  "Merienda",
  "Merienda One",
  "Merriweather",
  "Merriweather Sans",
  "Metal Mania",
  "Metamorphous",
  "Metrophobic",
  "Michroma",
  "Milonga",
  "Miltonian",
  "Miltonian Tattoo",
  "Mina",
  "Miniver",
  "Minuet",
  "Miriam Libre",
  "Mirza",
  "Mirza Basic",
  "Mitr",
  "Modak",
  "Modern Antiqua",
  "Mogra",
  "Molengo",
  "Molle",
  "Monda",
  "Monofett",
  "Monoton",
  "Monsieur La Doulaise",
  "Montaga",
  "Montez",
  "Montserrat",
  "Montserrat Alternates",
  "Montserrat Subrayada",
  "MoolBoran",
  "Moon",
  "Moulin Rouge",
  "Mountains of Christmas",
  "Mouse Memoirs",
  "Mr Bedfort",
  "Mr Dafoe",
  "Mr De Haviland",
  "Mrs Saint Delafield",
  "Mrs Sheppards",
  "Mukta",
  "Mukta Mahee",
  "Mukta Malar",
  "Mukta Vaani",
  "Mulish",
  "Myndraine",
  "Mystery Quest",
  "NTR",
  "Nanum Brush Script",
  "Nanum Gothic",
  "Nanum Gothic Coding",
  "Nanum Myeongjo",
  "Nanum Pen Script",
  "Neucha",
  "Neuton",
  "New Rocker",
  "News Cycle",
  "Niconne",
  "Niramit",
  "Nixie One",
  "Nobile",
  "Nokora",
  "Norican",
  "Norma",
  "Nosifer",
  "Notable",
  "Nothing You Could Do",
  "Noticia Text",
  "Noto Sans",
  "Noto Sans HK",
  "Noto Sans JP",
  "Noto Sans KR",
  "Noto Sans SC",
  "Noto Sans TC",
  "Noto Serif",
  "Noto Serif JP",
  "Noto Serif KR",
  "Noto Serif SC",
  "Noto Serif TC",
  "Nova Cut",
  "Nova Flat",
  "Nova Mono",
  "Nova Oval",
  "Nova Round",
  "Nova Script",
  "Nova Slim",
  "Nova Square",
  "Numans",
  "Nunito",
  "Nunito Sans",
  "Odibee Sans",
  "Offside",
  "Old Standard TT",
  "Oldenburg",
  "Oleo Script",
  "Oleo Script Swash Caps",
  "Open Sans",
  "Open Sans Condensed",
  "Oranienbaum",
  "Orbitron",
  "Oriya MN",
  "Oregano",
  "Orienta",
  "Original Surfer",
  "Oswald",
  "Over the Rainbow",
  "Overlock",
  "Overlock SC",
  "Overpass",
  "Overpass Mono",
  "Ovo",
  "Oxygen",
  "Oxygen Mono",
  "PT Mono",
  "PT Sans",
  "PT Sans Caption",
  "PT Sans Narrow",
  "PT Serif",
  "PT Serif Caption",
  "Pacifico",
  "Padauk",
  "Palanquin",
  "Palanquin Dark",
  "Pangolin",
  "Paprika",
  "Parisienne",
  "Passero One",
  "Passion One",
  "Pathway Gothic One",
  "Patrick Hand",
  "Patrick Hand SC",
  "Pattaya",
  "Patua One",
  "Paytone One",
  "Peddana",
  "Peralta",
  "Permanent Marker",
  "Petit Formal Script",
  "Petrona",
  "Philosopher",
  "Piazzolla",
  "Piedra",
  "Pinyon Script",
  "Pirata One",
  "Plaster",
  "Play",
  "Playball",
  "Playfair Display",
  "Playfair Display SC",
  "Podkova",
  "Poiret One",
  "Poller One",
  "Poly",
  "Pompiere",
  "Pontano Sans",
  "Poor Story",
  "Poppins",
  "Port Lligat Sans",
  "Port Lligat Slab",
  "Pragati Narrow",
  "Prata",
  "Press Start 2P",
  "Pridi",
  "Princess Sofia",
  "Prociono",
  "Prompt",
  "Prosto One",
  "Proza Libre",
  "Puritan",
  "Purple Purse",
  "Quando",
  "Quantico",
  "Quattrocento",
  "Quattrocento Sans",
  "Questrial",
  "Quicksand",
  "Quintessential",
  "Qwigley",
  "Racing Sans One",
  "Radley",
  "Rajdhani",
  "Rakkas",
  "Ramabhadra",
  "Ramaraja",
  "Rambla",
  "Rammetto One",
  "Ranchers",
  "Rancho",
  "Ranga",
  "Rasa",
  "Rationale",
  "Ravi Prakash",
  "Red Hat Display",
  "Red Hat Text",
  "Redressed",
  "Reem Kufi",
  "Reenie Beanie",
  "Reggae One",
  "Revalia",
  "Rhodium Libre",
  "Ribeye",
  "Ribeye Marrow",
  "Righteous",
  "Risque",
  "Roboto",
  "Roboto Condensed",
  "Roboto Mono",
  "Roboto Slab",
  "Rochester",
  "Rock Salt",
  "Rokkitt",
  "Romanesco",
  "Ropa Sans",
  "Rosario",
  "Rosarivo",
  "Rouge Script",
  "Rozha One",
  "Rubik",
  "Rubik Mono One",
  "Ruda",
  "Rufina",
  "Ruge Boogie",
  "Ruluko",
  "Rum Raisin",
  "Ruslan Display",
  "Russo One",
  "Ruthie",
  "Rye",
  "Saba",
  "Sacramento",
  "Sahitya",
  "Sail",
  "Saira",
  "Saira Condensed",
  "Saira Extra Condensed",
  "Saira Semi Condensed",
  "Sakhi",
  "Salsa",
  "Sanchez",
  "Sand",
  "Sandoll Gothic Neo",
  "Sandoll Heiti",
  "Sandoll Nanum Myeongjo",
  "Sandoll Nanum Pen Script",
  "Sansita",
  "Sarabun",
  "Sarala",
  "Sarina",
  "Sarpanch",
  "Satisfy",
  "Sawarabi Gothic",
  "Sawarabi Mincho",
  "Scada",
  "Scheherazade",
  "Schoolbell",
  "Scope One",
  "Seaweed Script",
  "Secular One",
  "Sedgwick Ave",
  "Sedgwick Ave Display",
  "Sevillana",
  "Seymour One",
  "Shadows Into Light",
  "Shadows Into Light Two",
  "Shanti",
  "Share Tech",
  "Share Tech Mono",
  "Shojumaru",
  "Short Stack",
  "Shrikhand",
  "Siemreap",
  "Sigmar One",
  "Signika",
  "Signika Negative",
  "Simonetta",
  "Single Day",
  "Sintony",
  "Sirin Stencil",
  "Six Caps",
  "Skranji",
  "Slackey",
  "Smokum",
  "Smythe",
  "Sniglet",
  "Snippet",
  "Snowburst One",
  "Sofadi One",
  "Sofia",
  "Solway",
  "Sonsie One",
  "Sorts Mill Goudy",
  "Source Code Pro",
  "Source Sans Pro",
  "Source Serif Pro",
  "Space Mono",
  "Special Elite",
  "Spectral",
  "Spicy Rice",
  "Spinnaker",
  "Spirax",
  "Squada One",
  "Sree Krushnadevaraya",
  "Staatliches",
  "Stalemate",
  "Stalinist One",
  "Stardos Stencil",
  "Stint Ultra Condensed",
  "Stint Ultra Expanded",
  "Stoke",
  "Strait",
  "Stylish",
  "Sue Ellen Francisco",
  "Suez One",
  "Sumana",
  "Sunflower",
  "Sunshiney",
  "Supermercado One",
  "Sura",
  "Suranna",
  "Suravaram",
  "Suwannaphum",
  "Swanky and Moo Moo",
  "Syncopate",
  "Tajawal",
  "Tangerine",
  "Taprom",
  "Tauri",
  "Taviraj",
  "Teko",
  "Telex",
  "Tenali Ramakrishna",
  "Tenor Sans",
  "Text Me One",
  "Thasadith",
  "The Girl Next Door",
  "Tienne",
  "Tinos",
  "Titan One",
  "Titillium Web",
  "Tomorrow",
  "Trade Winds",
  "Trirong",
  "Trocchi",
  "Trochut",
  "Trykker",
  "Tulpen One",
  "Tulsi",
  "Turret Road",
  "Ubuntu",
  "Ubuntu Condensed",
  "Ubuntu Mono",
  "Ultra",
  "Uncial Antiqua",
  "Underdog",
  "Unica One",
  "UnifrakturCook",
  "UnifrakturMaguntia",
  "Unkempt",
  "Unlock",
  "Unna",
  "VT323",
  "Vampiro One",
  "Varela",
  "Varela Round",
  "Vast Shadow",
  "Vesper Libre",
  "Vibur",
  "Vidaloka",
  "Viga",
  "Voces",
  "Volkhov",
  "Vollkorn",
  "Vollkorn SC",
  "Voltaire",
  "Waiting for the Sunrise",
  "Wallpoet",
  "Walter Turncoat",
  "Warnes",
  "Wellfleet",
  "Wendy One",
  "Wire One",
  "Work Sans",
  "Xanh Mono",
  "Yaldevi Colombo",
  "Yanone Kaffeesatz",
  "Yantramanav",
  "Yatra One",
  "Yellowtail",
  "Yeseva One",
  "Yesteryear",
  "Yrsa",
  "Yusei Magic",
  "ZCOOL KuaiLe",
  "ZCOOL QingKe HuangYou",
  "ZCOOL XiaoWei",
  "Zen Dots",
  "Zen Loop",
  "Zen Tokyo Zoo",
  "Zeyada",
  "Zhi Mang Xing",
  "Zilla Slab",
  "Zilla Slab Highlight",
  "Zilla Slab SemiBold",
  "Zilla Slab SemiBold Italic",
  "Zilla Slab SemiLight",
  "Zilla Slab SemiLight Italic",
  "Zilla Slab Thin",
  "Zilla Slab Thin Italic",
  "Zilla Slab Ultra",
  "Zilla Slab Ultra Italic"
];

export default fonts;
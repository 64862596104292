import { configureStore } from '@reduxjs/toolkit'

import commonValuesReducer from "./commonValues";
import loginTemplateReducer from "./loginTemplateValues";
import emailTemplateReducer from "./emailTemplateValues";
import smsTemplateReducer from "./smsTemplateValues";

const store = configureStore({
  reducer: {
    commonValues: commonValuesReducer,
    loginTemplateValues: loginTemplateReducer,
    emailTemplateValues: emailTemplateReducer,
    smsTemplateValues: smsTemplateReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;
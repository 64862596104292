import React, { useState } from "react";

function ColorPickerCard(props: {
  label: string;
  color: string;
  onColorChange: (color: string) => void;
}) {
  // set a temp color and limit call to update onColorChange to improve performance of color picker component
  const [tempColor, setTempColor] = useState(props.color);
  const [colorOnChangeTimeout, setColorOnChangeTimeout] = useState(setTimeout(() => { }, 250));

  return (
    <>
      <label htmlFor="buttonTextcolor">{props.label}</label>
      <div className="color">
        <span className="color-value">{props.color}</span>
        <input
          type="color"
          className="large"
          value={tempColor}
          onChange={(e) => {
            // In case timeout was already set, clear it
            clearTimeout(colorOnChangeTimeout);
            setTempColor(e.target.value);

            // Set the next timeout
            setColorOnChangeTimeout(
              setTimeout(() => {
                console.log("color change called: ", e.target.value)
                props.onColorChange(e.target.value);
              }, 250) // <-- Delay api call by 250 milliseconds. Set to what you prefer
            );
          }}
        />
      </div>
    </>
  )
  /*return (
    <div className="color-picker">
      <span>{props.label}</span>
      <div className="color-picker-display">
        <span className="color-value">{props.color}</span>
        <div className="color-window" style={{ "backgroundColor": props.color }}>
          <div style={{ "width": "100%", "height": "100%" }} onClick={() => { setHidePicker(!isPickerHidden) }} />
          {!isPickerHidden && (
            <ChromePicker
              className="color-picker-panel"
              disableAlpha
              color={value}
              onChange={(color) => { onChange(color.hex) }}
              onChangeComplete={props.onColorChange}
            />
          )}
        </div>
      </div>
    </div>
  );*/
}

export default ColorPickerCard;
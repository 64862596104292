import React, { useState } from "react";
import FontFamilyCard from "./fontFamilyCard";
import ColorPickerCard from "./colorPickerCard";
import LanguageCard from "../components/languagesCard";
import Editor from "./richTechEditor";
import SelectCard from "./selectCard";
import InputCard from "./inputCard";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setViewPort } from "../store/commonValues";
import {
  setAddEmailBody,
  setAddEmailSubject,
  setCopyrightAddress,
  setDeleteAccountEmailBody,
  setDeleteAccountEmailSubject,
  setEmailBackgroundColor,
  setEmailBrandColor,
  setEmailButtonBackgroundColor,
  setEmailButtonTextColor,
  setEmailFont,
  setEmailIndustry,
  setEmailLanguage,
  setEmailLinkColor,
  setEmailLogoAlignment,
  setEmailTextColor,
  setEmailTone,
  setForgotPasswordEmailBody,
  setForgotPasswordEmailSubject,
  setLinkPrivacyPolicy,
  setLinkTermAndCondition,
  setLinkUnsubscribe,
  setResetPasswordEmailBody,
  setResetPasswordEmailSubject,
  setSocialLinkDiscord,
  setSocialLinkFacebook,
  setSocialLinkInstagram,
  setSocialLinkLinkedin,
  setSocialLinkTwitter,
  setSocialLinkYoutube,
  setVerificationEmailBody,
  setVerificationEmailSubject,
  setWelcomeEmailBody,
  setWelcomeEmailSubject
} from "../store/emailTemplateValues";

function EmailThemeCustomize(props: {
  //common
  iframePage: string;
  iframePreviewLoad(templateName: string, page: string, action: string): string;

  // email
  generateAIEmail: (value: string) => void;
}) {
  const {
    viewPort
  } = useAppSelector(state => state.commonValues);
  const {
    emailGenerationLoading,
    welcomeEmailSubject,
    welcomeEmailBody,
    verificationEmailSubject,
    verificationEmailBody,
    forgotPasswordEmailSubject,
    forgotPasswordEmailBody,
    deleteAccountEmailSubject,
    deleteAccountEmailBody,
    addEmailSubject,
    addEmailBody,
    resetPasswordEmailSubject,
    resetPasswordEmailBody,
    emailLogoAlignment,
    emailBackgroundColor,
    emailBrandColor,
    emailTextColor,
    emailFont,
    emailIndustry,
    emailTone,
    emailLanguage,
    emailLinkColor,
    linkPrivacyPolicy,
    linkTermAndCondition,
    linkUnsubscribe,
    copyrightAddress,
    emailButtonBackgroundColor,
    emailButtonTextColor,
    socialLinkFacebook,
    socialLinkTwitter,
    socialLinkLinkedin,
    socialLinkInstagram,
    socialLinkYoutube,
    socialLinkDiscord,
    emailSelectedTemplate,
  } = useAppSelector(state => state.emailTemplateValues);
  const dispatch = useAppDispatch();

  const [iframeLoader, setIframeLoader] = useState(true);
  const industryOptions = [
    { label: "Technology/IT", value: "technology-it" },
    { label: "Healthcare/Medical", value: "healthcare-medical" },
    {
      label: "Finance/Financial Services",
      value: "finance-financial-services",
    },
    { label: "Travel and Tourism", value: "travel-and-tourism" },
    { label: "Fashion and Apparel", value: "fashion-and-apparel" },
    { label: "Food and Beverage", value: "food-and-beverage" },
    { label: "Education and E-Learning", value: "education-and-e-learning" },
    { label: "Legal Services and Law", value: "legal-services-and-law" },
    { label: "Automotive", value: "automotive" },
    {
      label: "Environmental and Green Energy",
      value: "environmental-and-green-energy",
    },
    { label: "Entertainment and Media", value: "entertainment-and-media" },
    { label: "Real Estate", value: "real-estate" },
    { label: "Sports and Fitness", value: "sports-and-fitness" },
    { label: "Marketing and Advertising", value: "marketing-and-advertising" },
    { label: "Hospitality and Hotel", value: "hospitality-and-hotel" },
    { label: "Nonprofits and Charities", value: "nonprofits-and-charities" },
    { label: "Science and Research", value: "science-and-research" },
    {
      label: "Government and Public Sector",
      value: "government-and-public-sector",
    },
    { label: "Home and Lifestyle", value: "home-and-lifestyle" },
    { label: "Gaming and Video Game", value: "gaming-and-video-game" },
    {
      label: "Agriculture and Agribusiness",
      value: "agriculture-and-agribusiness",
    },
    {
      label: "Manufacturing and Industrial",
      value: "manufacturing-and-industrial",
    },
    { label: "Retail and E-commerce", value: "retail-and-e-commerce" },
    { label: "Telecommunications", value: "telecommunications" },
    {
      label: "Transportation and Logistics",
      value: "transportation-and-logistics",
    },
    {
      label: "Pharmaceutical and Biotechnology",
      value: "pharmaceutical-and-biotechnology",
    },
    {
      label: "Construction and Real Estate Development",
      value: "construction-and-real-estate-development",
    },
    { label: "Consulting Services", value: "consulting-services" },
    { label: "Energy and Utilities", value: "energy-and-utilities" },
    { label: "Aerospace and Defense", value: "aerospace-and-defense" },
    { label: "Consumer Electronics", value: "consumer-electronics" },
    { label: "Health and Wellness", value: "health-and-wellness" },
    { label: "Insurance", value: "insurance" },
    { label: "Art and Creative", value: "art-and-creative" },
    {
      label: "Hospital and Healthcare Management",
      value: "hospital-and-healthcare-management",
    },
    {
      label: "Information Services and Publishing",
      value: "information-services-and-publishing",
    },
    { label: "Petroleum and Oil", value: "petroleum-and-oil" },
    {
      label: "Textile and Apparel Manufacturing",
      value: "textile-and-apparel-manufacturing",
    },
    { label: "Wine and Beverage", value: "wine-and-beverage" },
    { label: "Renewable Energy", value: "renewable-energy" },
  ];

  const toneOptions = [
    { value: "informative", label: "Informative" },
    { value: "professional", label: "Professional" },
    { value: "authoritative", label: "Authoritative" },
    { value: "compassionate", label: "Compassionate" },
    { value: "trustworthy", label: "Trustworthy" },
    { value: "credible", label: "Credible" },
    { value: "serious", label: "Serious" },
    { value: "knowledgeable", label: "Knowledgeable" },
    { value: "inspirational", label: "Inspirational" },
    { value: "adventurous", label: "Adventurous" },
    { value: "enthusiastic", label: "Enthusiastic" },
    { value: "trendy", label: "Trendy" },
    { value: "stylish", label: "Stylish" },
    { value: "aspirational", label: "Aspirational" },
    { value: "appetizing", label: "Appetizing" },
    { value: "descriptive", label: "Descriptive" },
    { value: "relatable", label: "Relatable" },
    { value: "encouraging", label: "Encouraging" },
    { value: "supportive", label: "Supportive" },
    { value: "formal", label: "Formal" },
    { value: "precise", label: "Precise" },
    { value: "energetic", label: "Energetic" },
    { value: "motivational", label: "Motivational" },
    { value: "creative", label: "Creative" },
    { value: "results-driven", label: "Results Driven" },
    { value: "welcoming", label: "Welcoming" },
    { value: "customer-centric", label: "Customer-centric" },
    { value: "accommodating", label: "Accommodating" },
    { value: "compassionate", label: "Compassionate" },
    { value: "purpose-driven", label: "Purpose-driven" },
    { value: "objective", label: "Objective" },
    { value: "data-driven", label: "Data-driven" },
    { value: "official", label: "Official" },
    { value: "neutral", label: "Neutral" },
    { value: "practical", label: "Practical" },
    { value: "cozy", label: "Cozy" },
    { value: "eco-conscious", label: "Eco-conscious" },
    { value: "urgent", label: "Urgent" },
    { value: "lively", label: "Lively" },
    { value: "immersive", label: "Immersive" },
    { value: "interactive", label: "Interactive" },
  ];

  const displayGenerateLabel = () => {
    if (emailGenerationLoading) {
      return "Generating";
    }

    if ((props.iframePage === "welcome" && welcomeEmailBody !== "") ||
      (props.iframePage === "verification" && verificationEmailBody !== "") ||
      (props.iframePage === "forgot-password" && forgotPasswordEmailBody !== "") ||
      (props.iframePage === "delete-account" && deleteAccountEmailBody !== "") ||
      (props.iframePage === "add-email" && addEmailBody !== "") ||
      (props.iframePage === "reset-password" && resetPasswordEmailBody !== "")) {
      return "Re-Generate";
    }

    return "Generate";
  }

  return (
    <div className="customization-wrapper">
      <div
        className={
          `toggle-btn` +
          (viewPort === 418 ? " hide" : " ") +
          (viewPort === 1070 ? " show" : " ")
        }
      >
        <button
          className={`btn-desktopview`}
          onClick={() => {
            dispatch(setViewPort(418));
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_813_10995)">
              <path
                d="M0.75 10C0.75 15.1086 4.89137 19.25 10 19.25C15.1086 19.25 19.25 15.1086 19.25 10C19.25 4.89137 15.1086 0.75 10 0.75C4.89137 0.75 0.75 4.89137 0.75 10Z"
                fill="white"
                stroke="#008ECF"
                strokeWidth="1.5"
              />
              <path
                d="M7.41725 15.4477L6.9224 14.9528C6.86614 14.8965 6.83453 14.8201 6.83453 14.7405C6.83453 14.6609 6.86614 14.5845 6.9224 14.5282L11.4399 10L6.9224 5.47186C6.86614 5.41553 6.83453 5.33917 6.83453 5.25955C6.83453 5.17994 6.86614 5.10358 6.9224 5.04725L7.41725 4.55241C7.47357 4.49614 7.54993 4.46454 7.62955 4.46454C7.70916 4.46454 7.78552 4.49614 7.84185 4.55241L13.0772 9.78773C13.1334 9.84406 13.165 9.92042 13.165 10C13.165 10.0796 13.1334 10.156 13.0772 10.2123L7.84185 15.4477C7.78552 15.5039 7.70916 15.5355 7.62955 15.5355C7.54993 15.5355 7.47357 15.5039 7.41725 15.4477Z"
                fill="#008ECF"
              />
            </g>
            <defs>
              <clipPath id="clip0_813_10995">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="matrix(-1 0 0 1 20 0)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
      <div
        className={
          `accordion-warpper` +
          (viewPort === 1070 ? " desktop-active" : " ")
        }
      >
        <div className="sub-header">
          <h2>Customization</h2>
        </div>
        <div className="accordion-tabs">
          <div className="accordion-tab">
            <input type="radio" id={`email${props.iframePage}`} name="rd" />
            <label
              className="accordion-tab-label"
              htmlFor={`email${props.iframePage}`}
            >
              Body
            </label>
            <div className="accordion-tab-content">
              <div className="customization-form">
                <div className="form-control ">
                  <label htmlFor="logoalignment">Logo Alignment</label>
                  <div className="btn-group action-buttons logo-alignment">
                    <div>
                      <button
                        className={`btn-left${emailLogoAlignment === "flex-start" ? " active" : ""}`}
                        onClick={() => dispatch(setEmailLogoAlignment("flex-start"))}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 6.99998H20.9999M3 12H15M3 16.9999H6.99999"
                            stroke="#757A7E"
                            strokeWidth="1.99999"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <button
                        className={`btn-center${emailLogoAlignment === "center" ? " active" : ""}`}
                        onClick={() => dispatch(setEmailLogoAlignment("center"))}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 6.99998H20.9999M5.99999 12H17.9999M9.99997 16.9999H14"
                            stroke="#757A7E"
                            strokeWidth="1.99999"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <button
                        className={`btn-right${emailLogoAlignment === "flex-end" ? " active" : ""}`}
                        onClick={() => dispatch(setEmailLogoAlignment("flex-end"))}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 6.99998H20.9999M8.99998 12H20.9999M16.9999 16.9999H20.9999"
                            stroke="#757A7E"
                            strokeWidth="1.99999"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-control ">
                  <ColorPickerCard
                    label="Background Color"
                    color={emailBackgroundColor}
                    onColorChange={(emailBackgroundColor) => {
                      dispatch(setEmailBackgroundColor(emailBackgroundColor));
                    }}
                  />
                </div>
                <div className="form-control ">
                  <ColorPickerCard
                    label="Brand Color"
                    color={emailBrandColor}
                    onColorChange={(emailBrandColor) => {
                      dispatch(setEmailBrandColor(emailBrandColor));
                    }}
                  />
                </div>
                <div className="form-control ">
                  <ColorPickerCard
                    label="Text Color"
                    color={emailTextColor}
                    onColorChange={(emailTextColor) => {
                      dispatch(setEmailTextColor(emailTextColor));
                    }}
                  />
                </div>
                <div className="form-control ">
                  <FontFamilyCard
                    label="Font Family"
                    font={emailFont}
                    setFont={(val) => dispatch(setEmailFont(val))}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-tab">
            <input type="radio" id={`rd2${props.iframePage}`} name="rd" />
            <label
              className="accordion-tab-label"
              htmlFor={`rd2${props.iframePage}`}
            >
              {props.iframePage === "login"
                ? "Content  "
                : "AI Generated Content"}
            </label>
            <div className="accordion-tab-content">
              <div className="customization-form">
                <div className="form-control ">
                  <SelectCard
                    label={`Industry`}
                    options={[{ label: emailIndustry, value: emailIndustry.toLowerCase().split(' ').join('-') }].concat(industryOptions)}
                    value={emailIndustry}
                    setValue={(val) => dispatch(setEmailIndustry(val))}
                  />
                </div>

                <div className="form-control ">
                  <SelectCard
                    label={`Tone`}
                    options={toneOptions}
                    value={emailTone}
                    setValue={(val) => dispatch(setEmailTone(val))}
                  />
                </div>
                <div className="form-control ">
                  <LanguageCard
                    label="Languages"
                    value={emailLanguage}
                    setValue={(val) => dispatch(setEmailLanguage(val))}
                  />
                </div>
                {props.iframePage === "welcome" &&
                  welcomeEmailBody !== "" ? (
                  <>
                    <div className="form-control ">
                      <InputCard
                        label={`Subject`}
                        placeholder=""
                        type={"text"}
                        value={welcomeEmailSubject}
                        setValue={(value) => {
                          dispatch(setWelcomeEmailSubject(value))
                        }}
                      />
                    </div>
                    <div className="form-control ">
                      <Editor
                        label={`Body Text`}
                        value={welcomeEmailBody}
                        setValue={(value) => {
                          dispatch(setWelcomeEmailBody(value))
                        }}
                      />
                    </div>
                  </>
                ) : props.iframePage === "verification" &&
                  verificationEmailBody !== "" ? (
                  <>
                    <div className="form-control ">
                      <InputCard
                        label={`Subject`}
                        placeholder=""
                        type={"text"}
                        value={
                          verificationEmailSubject
                        }
                        setValue={(value) => {
                          dispatch(setVerificationEmailSubject(value))
                        }}
                      />
                    </div>
                    <div className="form-control ">
                      <Editor
                        label={`Body Text`}
                        value={verificationEmailBody}
                        setValue={(value) => {
                          dispatch(setVerificationEmailBody(value))
                        }}
                      />
                    </div>
                  </>
                ) : props.iframePage === "forgot-password" &&
                  forgotPasswordEmailBody !== "" ? (
                  <>
                    <div className="form-control ">
                      <InputCard
                        label={`Subject`}
                        placeholder=""
                        type={"text"}
                        value={
                          forgotPasswordEmailSubject
                        }
                        setValue={(value) => {
                          dispatch(setForgotPasswordEmailSubject(value))
                        }}
                      />
                    </div>
                    <div className="form-control ">
                      <Editor
                        label={`Body Text`}
                        value={
                          forgotPasswordEmailBody
                        }
                        setValue={(value) => {
                          dispatch(setForgotPasswordEmailBody(value))
                        }}
                      />
                    </div>
                  </>
                ) : props.iframePage === "delete-account" &&
                  deleteAccountEmailBody !== "" ? (
                  <>
                    <div className="form-control ">
                      <InputCard
                        label={`Subject`}
                        placeholder=""
                        type={"text"}
                        value={
                          deleteAccountEmailSubject
                        }
                        setValue={(value) => {
                          dispatch(setDeleteAccountEmailSubject(value))
                        }}
                      />
                    </div>
                    <div className="form-control ">
                      <Editor
                        label={`Body Text`}
                        value={deleteAccountEmailBody}
                        setValue={(value) => {
                          dispatch(setDeleteAccountEmailBody(value))
                        }}
                      />
                    </div>
                  </>
                ) : props.iframePage === "add-email" &&
                  addEmailBody !== "" ? (
                  <>
                    <div className="form-control ">
                      <InputCard
                        label={`Subject`}
                        placeholder=""
                        type={"text"}
                        value={
                          addEmailSubject
                        }
                        setValue={(value) => {
                          dispatch(setAddEmailSubject(value))
                        }}
                      />
                    </div>
                    <div className="form-control ">
                      <Editor
                        label={`Body Text`}
                        value={addEmailBody}
                        setValue={(value) => {
                          dispatch(setAddEmailBody(value))
                        }}
                      />
                    </div>
                  </>
                ) : props.iframePage === "reset-password" &&
                  resetPasswordEmailBody !== "" ? (
                  <>
                    <div className="form-control ">
                      <InputCard
                        label={`Subject`}
                        placeholder=""
                        type={"text"}
                        value={
                          resetPasswordEmailSubject
                        }
                        setValue={(value) => {
                          dispatch(setResetPasswordEmailSubject(value))
                        }}
                      />
                    </div>
                    <div className="form-control ">
                      <Editor
                        label={`Body Text`}
                        value={resetPasswordEmailBody}
                        setValue={(value) => {
                          dispatch(setResetPasswordEmailBody(value))
                        }}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="form-control">
                  <div className="align-right">
                    <button
                      className={`btn btn-primary${emailGenerationLoading ? " disabled" : ""}`}
                      onClick={() => {
                        props.generateAIEmail(props.iframePage)
                      }}
                    >
                      {displayGenerateLabel()}
                      <img
                        alt="loading"
                        className={`${!emailGenerationLoading ? "hide" : ""}`}
                        src="/loading-white.svg"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-tab">
            <input type="radio" id={`rd4${props.iframePage}`} name="rd" />
            <label
              className="accordion-tab-label"
              htmlFor={`rd4${props.iframePage}`}
            >
              Links
            </label>
            <div className="accordion-tab-content">
              <div className="customization-form">
                <div className="form-control ">
                  <ColorPickerCard
                    label="Link color"
                    color={emailLinkColor}
                    onColorChange={(emailLinkColor) => {
                      dispatch(setEmailLinkColor(emailLinkColor));
                    }}
                  />
                </div>
                <div className="form-control">
                  <InputCard
                    label={`Privacy policy`}
                    placeholder=""
                    type={"text"}
                    value={linkPrivacyPolicy}
                    setValue={(value) => {
                      dispatch(setLinkPrivacyPolicy(value))
                    }}
                  />
                </div>
                <div className="form-control">
                  <InputCard
                    label={`Term and condition`}
                    placeholder=""
                    type={"text"}
                    value={linkTermAndCondition}
                    setValue={(value) => {
                      dispatch(setLinkTermAndCondition(value));
                    }}
                  />
                </div>
                <div className="form-control">
                  <InputCard
                    label={`Unsubscribe Link`}
                    placeholder=""
                    type={"text"}
                    value={linkUnsubscribe}
                    setValue={(value) => {
                      dispatch(setLinkUnsubscribe(value));
                    }}
                  />
                </div>
                <div className="form-control">
                  <InputCard
                    label={`Copyright Address`}
                    placeholder=""
                    type={"text"}
                    value={copyrightAddress}
                    setValue={(value) => {
                      dispatch(setCopyrightAddress(value))
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-tab">
            <input type="radio" id={`rd5${props.iframePage}`} name="rd" />
            <label
              className="accordion-tab-label"
              htmlFor={`rd5${props.iframePage}`}
            >
              Button
            </label>
            <div className="accordion-tab-content">
              <div className="customization-form">
                <div className="form-control ">
                  <ColorPickerCard
                    label="Background color"
                    color={emailButtonBackgroundColor}
                    onColorChange={(emailButtonBackgroundColor) => {
                      dispatch(setEmailButtonBackgroundColor(emailButtonBackgroundColor));
                    }}
                  />
                </div>
                <div className="form-control ">
                  <ColorPickerCard
                    label="Text color"
                    color={emailButtonTextColor}
                    onColorChange={(emailButtonTextColor) => {
                      dispatch(setEmailButtonTextColor(emailButtonTextColor));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-tab">
            <input type="radio" id={`rd6${props.iframePage}`} name="rd" />
            <label
              className="accordion-tab-label"
              htmlFor={`rd6${props.iframePage}`}
            >
              Social Links
            </label>
            <div className="accordion-tab-content">
              <div className="customization-form">
                <div className="form-control">
                  <InputCard
                    label={`Facebook`}
                    placeholder=""
                    type={"text"}
                    value={socialLinkFacebook}
                    setValue={(value) => {
                      dispatch(setSocialLinkFacebook(value))
                    }}
                  />
                </div>
                <div className="form-control">
                  <InputCard
                    label={`Twitter`}
                    placeholder=""
                    type={"text"}
                    value={socialLinkTwitter}
                    setValue={(value) => {
                      dispatch(setSocialLinkTwitter(value))
                    }}
                  />
                </div>
                <div className="form-control">
                  <InputCard
                    label={`Linkedin`}
                    placeholder=""
                    type={"text"}
                    value={socialLinkLinkedin}
                    setValue={(value) => {
                      dispatch(setSocialLinkLinkedin(value))
                    }}
                  />
                </div>
                <div className="form-control">
                  <InputCard
                    label={`Instagram`}
                    placeholder=""
                    type={"text"}
                    value={socialLinkInstagram}
                    setValue={(value) => {
                      dispatch(setSocialLinkInstagram(value))
                    }}
                  />
                </div>
                <div className="form-control">
                  <InputCard
                    label={`Youtube`}
                    placeholder=""
                    type={"text"}
                    value={socialLinkYoutube}
                    setValue={(value) => {
                      dispatch(setSocialLinkYoutube(value))
                    }}
                  />
                </div>
                <div className="form-control">
                  <InputCard
                    label={`Discord`}
                    placeholder=""
                    type={"text"}
                    value={socialLinkDiscord}
                    setValue={(value) => {
                      dispatch(setSocialLinkDiscord(value))
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="customization-preview"
        style={{ width: `${viewPort === 1070 ? "100%" : ""}` }}
      >
        <div
          className={`toggle-btn` + (viewPort === 1070 ? "" : " mobile")}
        >
          <button
            className={
              `btn-mobileview` + (viewPort === 1070 ? " hide" : " show ")
            }
            onClick={() => {
              dispatch(setViewPort(1070));
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_813_10995)">
                <path
                  d="M0.75 10C0.75 15.1086 4.89137 19.25 10 19.25C15.1086 19.25 19.25 15.1086 19.25 10C19.25 4.89137 15.1086 0.75 10 0.75C4.89137 0.75 0.75 4.89137 0.75 10Z"
                  fill="white"
                  stroke="#008ECF"
                  strokeWidth="1.5"
                />
                <path
                  d="M12.5823 4.55241L13.0771 5.04725C13.1334 5.10358 13.165 5.17994 13.165 5.25955C13.165 5.33917 13.1334 5.41553 13.0771 5.47186L8.55965 10L13.0771 14.5282C13.1334 14.5845 13.165 14.6609 13.165 14.7405C13.165 14.8201 13.1334 14.8965 13.0771 14.9528L12.5823 15.4476C12.5259 15.5039 12.4496 15.5355 12.37 15.5355C12.2903 15.5355 12.214 15.5039 12.1577 15.4476L6.92235 10.2123C6.86608 10.156 6.83447 10.0796 6.83447 10C6.83447 9.92041 6.86608 9.84405 6.92235 9.78773L12.1577 4.55241C12.214 4.49614 12.2903 4.46454 12.37 4.46454C12.4496 4.46454 12.5259 4.49614 12.5823 4.55241Z"
                  fill="#008ECF"
                />
              </g>
              <defs>
                <clipPath id="clip0_813_10995">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="matrix(-1 0 0 1 20 0)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
        <div
          className={`iframe${viewPort === 1070 ? " desktopBrower" : " mobileBrower"
            }`}
        >
          <iframe
            className="cardTemplate"
            title="idx Preview"
            style={{ width: `100%`, height: `100vh` }}
            srcDoc={iframeLoader === false ? undefined : "loading..."}
            onLoad={(e) => {
              setIframeLoader(false);
            }}
            onChange={() => { setIframeLoader(true); }}
            src={props.iframePreviewLoad(
              emailSelectedTemplate,
              props.iframePage,
              "email"
            )}
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default EmailThemeCustomize;

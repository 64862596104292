function ColorThemeCard(props: {
  key: number;
  colors: string[];
  isSelected: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className={`color-theme${props.isSelected ? " selected" : ""}`}
      onClick={props.onClick}
    >
      {props.colors.map((color) => (
        <div key={color} className="color-window" style={{ backgroundColor: color }} />
      ))}
    </div>
  );
}

export default ColorThemeCard;

import React from "react";

function SmsRichTechEditor(props: {
  label: string;
  value: string;
  setValue: (value: string) => void;
}) {
  return (
    <div className="form-control editor">
      <span>{props.label}</span>
      <textarea
        onChange={(e) => {
          props.setValue(e.target.value)
        }}
        value={props.value}
      ></textarea>
    </div>
  );
}

export default SmsRichTechEditor;

import React, { useState } from "react";
import FontFamilyCard from "./fontFamilyCard";
import ColorPickerCard from "./colorPickerCard";
import InputCard from "./inputCard";
import LanguageCard from "./languagesCard";
import {
  LoginSocialButtonLayoutEnum,
  setLoginBackgroundColor,
  setLoginBackgroundImage,
  setLoginBrandColor,
  setLoginButtonBackgroundColor,
  setLoginButtonTextColor,
  setLoginCardColor,
  setLoginFont,
  setLoginInputBackgroundColor,
  setLoginInputBorderColor,
  setLoginLanguage,
  setLoginLinkColor,
  setLoginSocialButtonLayout,
  setLoginTemplateContent,
  setLoginTextColor
} from "../store/loginTemplateValues";
import { setViewPort } from "../store/commonValues";

import { useAppDispatch, useAppSelector } from "../store/hooks";

function ThemeCustomize(props: {
  iframePreviewLoad(templateName: string, page: string, action: string): string;
  iframePage: string;
}) {
  const {
    viewPort
  } = useAppSelector(state => state.commonValues)
  const {
    loginBackgroundImage,
    loginBackgroundColor,
    loginBrandColor,
    loginTextColor,
    loginButtonTextColor,
    loginCardColor,
    loginFont,
    loginLanguage,
    loginSelectedTemplate,
    loginLinkColor,
    loginInputBackgroundColor,
    loginInputBorderColor,
    loginButtonBackgroundColor,
    loginSocialButtonLayout,
    loginTemplateContent,
  } = useAppSelector(state => state.loginTemplateValues)
  const dispatch = useAppDispatch();
  const [iframeLoader, setIframeLoader] = useState(true);
  return (
    <div className="customization-wrapper">
      <div className="toggle-btn">
        <button
          className={
            `btn-desktopview` + (viewPort === 1070 ? " show" : " hide ")
          }
          onClick={() => {
            dispatch(setViewPort(418));
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_813_10995)">
              <path
                d="M0.75 10C0.75 15.1086 4.89137 19.25 10 19.25C15.1086 19.25 19.25 15.1086 19.25 10C19.25 4.89137 15.1086 0.75 10 0.75C4.89137 0.75 0.75 4.89137 0.75 10Z"
                fill="white"
                stroke="#008ECF"
                strokeWidth="1.5"
              />
              <path
                d="M7.41725 15.4477L6.9224 14.9528C6.86614 14.8965 6.83453 14.8201 6.83453 14.7405C6.83453 14.6609 6.86614 14.5845 6.9224 14.5282L11.4399 10L6.9224 5.47186C6.86614 5.41553 6.83453 5.33917 6.83453 5.25955C6.83453 5.17994 6.86614 5.10358 6.9224 5.04725L7.41725 4.55241C7.47357 4.49614 7.54993 4.46454 7.62955 4.46454C7.70916 4.46454 7.78552 4.49614 7.84185 4.55241L13.0772 9.78773C13.1334 9.84406 13.165 9.92042 13.165 10C13.165 10.0796 13.1334 10.156 13.0772 10.2123L7.84185 15.4477C7.78552 15.5039 7.70916 15.5355 7.62955 15.5355C7.54993 15.5355 7.47357 15.5039 7.41725 15.4477Z"
                fill="#008ECF"
              />
            </g>
            <defs>
              <clipPath id="clip0_813_10995">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="matrix(-1 0 0 1 20 0)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
      <div
        className={
          `accordion-warpper` +
          (viewPort === 1070 ? " desktop-active" : " ")
        }
      >
        <div className="sub-header">
          <h2>Customization</h2>
        </div>
        <div className="accordion-tabs">
          <div className="accordion-tab">
            <input type="radio" id={`login${props.iframePage}`} name="rd" />
            <label
              className="accordion-tab-label"
              htmlFor={`login${props.iframePage}`}
            >
              Body
            </label>
            <div className="accordion-tab-content">
              <div className="customization-form">
                <div className="form-control ">
                  <InputCard
                    label="Background Image"
                    placeholder="Enter Image URL"
                    type={"text"}
                    value={loginBackgroundImage}
                    setValue={(val) => dispatch(setLoginBackgroundImage(val))}
                  />
                </div>
                <div className="form-control ">
                  <ColorPickerCard
                    label="Background Color"
                    color={loginBackgroundColor}
                    onColorChange={(color) => {
                      dispatch(setLoginBackgroundColor(color));
                    }}
                  />
                </div>
                <div className="form-control ">
                  <ColorPickerCard
                    label="Card Color"
                    color={loginCardColor}
                    onColorChange={(color) => {
                      dispatch(setLoginCardColor(color));
                    }}
                  />
                </div>
                <div className="form-control ">
                  <ColorPickerCard
                    label="Brand Color"
                    color={loginBrandColor}
                    onColorChange={(color) => {
                      dispatch(setLoginBrandColor(color));
                    }}
                  />
                </div>
                <div className="form-control ">
                  <ColorPickerCard
                    label="Text Color"
                    color={loginTextColor}
                    onColorChange={(color) => {
                      dispatch(setLoginTextColor(color));
                    }}
                  />
                </div>
                <div className="form-control ">
                  <FontFamilyCard
                    label="Font Family"
                    font={loginFont}
                    setFont={(font) => dispatch(setLoginFont(font))}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-tab">
            <input type="radio" id={`rd2${props.iframePage}`} name="rd" />
            <label
              className="accordion-tab-label"
              htmlFor={`rd2${props.iframePage}`}
            >
              Content
            </label>
            <div className="accordion-tab-content">
              <div className="form-control ">
                <LanguageCard
                  label="Languages"
                  value={loginLanguage}
                  setValue={(lan) => dispatch(setLoginLanguage(lan))}
                />
              </div>
              {props.iframePage === "login" ? (
                <>
                  <h3>Sign in</h3>
                  <div className="customization-form">
                    <div className="form-control">
                      <InputCard
                        label="Page Title"
                        placeholder=""
                        type={"text"}
                        value={loginTemplateContent.signinTitle}
                        setValue={(value) => {
                          dispatch(setLoginTemplateContent({
                            ...loginTemplateContent,
                            signinTitle: value,
                          }));
                        }}
                      />
                    </div>
                    <div className="form-control">
                      <InputCard
                        label="Page Subtitle"
                        placeholder=""
                        type={"text"}
                        value={loginTemplateContent.signinSubtitle}
                        setValue={(value) => {
                          dispatch(setLoginTemplateContent({
                            ...loginTemplateContent,
                            signinSubtitle: value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {props.iframePage === "signup" ? (
                <>
                  <h3>Sign up</h3>
                  <div className="customization-form">
                    <div className="form-control">
                      <InputCard
                        label="Page Title"
                        placeholder=""
                        type={"text"}
                        value={loginTemplateContent.signupTitle}
                        setValue={(value) => {
                          dispatch(setLoginTemplateContent({
                            ...loginTemplateContent,
                            signupTitle: value,
                          }));
                        }}
                      />
                    </div>
                    <div className="form-control">
                      <InputCard
                        label="Page Subtitle"
                        placeholder=""
                        type={"text"}
                        value={loginTemplateContent.signupSubtitle}
                        setValue={(value) => {
                          dispatch(setLoginTemplateContent({
                            ...loginTemplateContent,
                            signupSubtitle: value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {props.iframePage === "forgotten-password" ? (
                <>
                  <h3>Forgot password</h3>
                  <div className="customization-form">
                    <div className="form-control">
                      <InputCard
                        label="Page Title"
                        placeholder=""
                        type={"text"}
                        value={loginTemplateContent.forgotPasswordTitle}
                        setValue={(value) => {
                          dispatch(setLoginTemplateContent({
                            ...loginTemplateContent,
                            forgotPasswordTitle: value,
                          }));
                        }}
                      />
                    </div>
                    <div className="form-control">
                      <InputCard
                        label="Page Subtitle"
                        placeholder=""
                        type={"text"}
                        value={
                          loginTemplateContent.forgotPasswordSubtitle
                        }
                        setValue={(value) => {
                          dispatch(setLoginTemplateContent({
                            ...loginTemplateContent,
                            forgotPasswordSubtitle: value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {props.iframePage === "mfa" ? (
                <>
                  <h3>MFA</h3>
                  <div className="customization-form">
                    <div className="form-control">
                      <InputCard
                        label="Page Title"
                        placeholder=""
                        type={"text"}
                        value={loginTemplateContent.mfaTitle}
                        setValue={(value) => {
                          dispatch(setLoginTemplateContent({
                            ...loginTemplateContent,
                            mfaTitle: value,
                          }));
                        }}
                      />
                    </div>
                    <div className="form-control">
                      <InputCard
                        label="Page Subtitle"
                        placeholder=""
                        type={"text"}
                        value={loginTemplateContent.mfaSubtitle}
                        setValue={(value) => {
                          dispatch(setLoginTemplateContent({
                            ...loginTemplateContent,
                            mfaSubtitle: value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="accordion-tab">
            <input type="radio" id={`rd3${props.iframePage}`} name="rd" />
            <label
              className="accordion-tab-label"
              htmlFor={`rd3${props.iframePage}`}
            >
              Input Fields
            </label>
            <div className="accordion-tab-content">
              <div className="customization-form">
                <div className="form-control ">
                  <ColorPickerCard
                    label="Background color"
                    color={loginInputBackgroundColor}
                    onColorChange={(color) => {
                      dispatch(setLoginInputBackgroundColor(color));
                    }}
                  />
                </div>
                <div className="form-control ">
                  <ColorPickerCard
                    label="Border color"
                    color={loginInputBorderColor}
                    onColorChange={(color) => {
                      dispatch(setLoginInputBorderColor(color));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-tab">
            <input type="radio" id={`rd4${props.iframePage}`} name="rd" />
            <label
              className="accordion-tab-label"
              htmlFor={`rd4${props.iframePage}`}
            >
              Links
            </label>
            <div className="accordion-tab-content">
              <div className="customization-form">
                <div className="form-control ">
                  <ColorPickerCard
                    label="Link color"
                    color={loginLinkColor}
                    onColorChange={(color) => {
                      dispatch(setLoginLinkColor(color));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-tab">
            <input type="radio" id={`rd5${props.iframePage}`} name="rd" />
            <label
              className="accordion-tab-label"
              htmlFor={`rd5${props.iframePage}`}
            >
              Submit Button
            </label>
            <div className="accordion-tab-content">
              <div className="customization-form">
                <div className="form-control ">
                  <ColorPickerCard
                    label="Background color"
                    color={loginButtonBackgroundColor}
                    onColorChange={(color) => {
                      dispatch(setLoginButtonBackgroundColor(color));
                    }}
                  />
                </div>
                <div className="form-control ">
                  <ColorPickerCard
                    label="Text color"
                    color={loginButtonTextColor}
                    onColorChange={(color) => {
                      dispatch(setLoginButtonTextColor(color));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-tab">
            <input type="radio" id={`rd6${props.iframePage}`} name="rd" />
            <label
              className="accordion-tab-label"
              htmlFor={`rd6${props.iframePage}`}
            >
              Social button layout
            </label>
            <div className="accordion-tab-content">
              <div className="customization-form">
                <div className="btn-group action-buttons">
                  <div>
                    <button
                      className={`btn-desktopview${loginSocialButtonLayout === LoginSocialButtonLayoutEnum.TOP ? " active" : ""
                        }`}
                      onClick={() => {
                        dispatch(setLoginSocialButtonLayout(LoginSocialButtonLayoutEnum.TOP));
                      }}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17 2H7C5.89543 2 5 2.89543 5 4V20C5 21.1046 5.89543 22 7 22H17C18.1046 22 19 21.1046 19 20V4C19 2.89543 18.1046 2 17 2Z"
                          stroke="#41484E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 6H16"
                          stroke="#41484E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path
                          d="M8 9H16"
                          stroke="#41484E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>
                    <button
                      className={`btn-mobileview${loginSocialButtonLayout === LoginSocialButtonLayoutEnum.BOTTOM ? " active" : ""
                        }`}
                      onClick={() => {
                        dispatch(setLoginSocialButtonLayout(LoginSocialButtonLayoutEnum.BOTTOM));
                      }}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 22L17 22C18.1046 22 19 21.1046 19 20L19 4C19 2.89543 18.1046 2 17 2L7 2C5.89543 2 5 2.89543 5 4L5 20C5 21.1046 5.89543 22 7 22Z"
                          stroke="#41484E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16 18L8 18"
                          stroke="#41484E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path
                          d="M16 15L8 15"
                          stroke="#41484E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="customization-preview"
        style={{ width: `${viewPort === 1070 ? "100%" : ""}` }}
      >
        <div
          className={`toggle-btn` + (viewPort === 1070 ? "" : " mobile")}
        >
          <button
            className={
              `btn-mobileview` + (viewPort === 1070 ? " hide" : " show ")
            }
            onClick={() => {
              dispatch(setViewPort(1070));
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_813_10995)">
                <path
                  d="M0.75 10C0.75 15.1086 4.89137 19.25 10 19.25C15.1086 19.25 19.25 15.1086 19.25 10C19.25 4.89137 15.1086 0.75 10 0.75C4.89137 0.75 0.75 4.89137 0.75 10Z"
                  fill="white"
                  stroke="#008ECF"
                  strokeWidth="1.5"
                />
                <path
                  d="M12.5823 4.55241L13.0771 5.04725C13.1334 5.10358 13.165 5.17994 13.165 5.25955C13.165 5.33917 13.1334 5.41553 13.0771 5.47186L8.55965 10L13.0771 14.5282C13.1334 14.5845 13.165 14.6609 13.165 14.7405C13.165 14.8201 13.1334 14.8965 13.0771 14.9528L12.5823 15.4476C12.5259 15.5039 12.4496 15.5355 12.37 15.5355C12.2903 15.5355 12.214 15.5039 12.1577 15.4476L6.92235 10.2123C6.86608 10.156 6.83447 10.0796 6.83447 10C6.83447 9.92041 6.86608 9.84405 6.92235 9.78773L12.1577 4.55241C12.214 4.49614 12.2903 4.46454 12.37 4.46454C12.4496 4.46454 12.5259 4.49614 12.5823 4.55241Z"
                  fill="#008ECF"
                />
              </g>
              <defs>
                <clipPath id="clip0_813_10995">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="matrix(-1 0 0 1 20 0)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
        <div
          className={`iframe${viewPort === 1070 ? " desktopBrower" : " mobileBrower"
            }`}
        >
          <iframe
            className="cardTemplate"
            title="idx Preview"
            style={{ width: `100%`, height: `100vh` }}
            srcDoc={iframeLoader === false ? undefined : "loading..."}
            onLoad={(e) => {
              setIframeLoader(false);
            }}
            onChange={() => { setIframeLoader(true); }}
            src={props.iframePreviewLoad(
              loginSelectedTemplate,
              props.iframePage,
              "login"
            )}
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default ThemeCustomize;

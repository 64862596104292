import { createSlice } from '@reduxjs/toolkit'

export interface emailTemplateInterface {
  emailTemplates: string[],
  emailFont: string,
  emailSelectedTemplate: string,
  emailSelectedTab: number,
  emailBrandColor: string,
  emailTextColor: string,
  emailBackgroundColor: string,
  emailCardColor: string,
  emailLinkColor: string,
  emailButtonBackgroundColor: string,
  emailButtonTextColor: string,
  emailLogoAlignment: "flex-start" | "center" | "flex-end",
  emailLanguage: string,
  emailIndustry: string,
  emailTone: string,
  emailGenerationLoading: boolean,
  welcomeEmailSubject: string,
  welcomeEmailBody: string,
  verificationEmailSubject: string,
  verificationEmailBody: string,
  resetPasswordEmailSubject: string,
  resetPasswordEmailBody: string,
  forgotPasswordEmailSubject: string,
  forgotPasswordEmailBody: string,
  deleteAccountEmailSubject: string,
  deleteAccountEmailBody: string,
  addEmailSubject: string,
  addEmailBody: string,
  socialLinkFacebook: string,
  socialLinkTwitter: string,
  socialLinkLinkedin: string,
  socialLinkInstagram: string,
  socialLinkYoutube: string,
  socialLinkDiscord: string,
  linkPrivacyPolicy: string,
  linkTermAndCondition: string,
  linkUnsubscribe: string,
  copyrightAddress: string,
}

const initialStateValue: emailTemplateInterface = {
  emailTemplates: ["001", "002", "003", "005"],
  emailFont: "Barlow",
  emailSelectedTemplate: "001",
  emailSelectedTab: 5,
  emailBrandColor: "#048ccc",
  emailTextColor: "#048ccc",
  emailBackgroundColor: "#0454ac",
  emailCardColor: "#0454ac",
  emailLinkColor: "#000000",
  emailButtonBackgroundColor: "#000000",
  emailButtonTextColor: "#000000",
  emailLogoAlignment: "flex-start",
  emailLanguage: "en",
  emailIndustry: "",
  emailTone: "professional",
  emailGenerationLoading: false,
  welcomeEmailSubject: "",
  welcomeEmailBody: "",
  verificationEmailSubject: "",
  verificationEmailBody: "",
  resetPasswordEmailSubject: "",
  resetPasswordEmailBody: "",
  forgotPasswordEmailSubject: "",
  forgotPasswordEmailBody: "",
  deleteAccountEmailSubject: "",
  deleteAccountEmailBody: "",
  addEmailSubject: "",
  addEmailBody: "",
  socialLinkFacebook: "",
  socialLinkTwitter: "",
  socialLinkLinkedin: "",
  socialLinkInstagram: "",
  socialLinkYoutube: "",
  socialLinkDiscord: "",
  linkPrivacyPolicy: "",
  linkTermAndCondition: "",
  linkUnsubscribe: "",
  copyrightAddress: "",
};

export const emailTemplateValuesSlice = createSlice({
  name: 'commonValues',
  initialState: initialStateValue,
  reducers: {
    resetEmailTemplateValues: (state) => {
      state = initialStateValue;
    },
    setEmailFont: (state, action) => {
      if (action.payload) {
        state.emailFont = action.payload;
      } else {
        state.emailFont = initialStateValue.emailFont
      }
    },
    setEmailSelectedTemplate: (state, action) => {
      if (action.payload) {
        state.emailSelectedTemplate = action.payload;
      } else {
        state.emailSelectedTemplate = initialStateValue.emailSelectedTemplate
      }
    },
    setEmailSelectedTab: (state, action) => {
      if (action.payload) {
        state.emailSelectedTab = action.payload;
      } else {
        state.emailSelectedTab = initialStateValue.emailSelectedTab
      }
    },
    setEmailBrandColor: (state, action) => {
      if (action.payload) {
        state.emailBrandColor = action.payload;
      } else {
        state.emailBrandColor = initialStateValue.emailBrandColor
      }
    },
    setEmailTextColor: (state, action) => {
      if (action.payload) {
        state.emailTextColor = action.payload;
      } else {
        state.emailTextColor = initialStateValue.emailTextColor
      }
    },
    setEmailBackgroundColor: (state, action) => {
      if (action.payload) {
        state.emailBackgroundColor = action.payload;
      } else {
        state.emailBackgroundColor = initialStateValue.emailBackgroundColor
      }
    },
    setEmailCardColor: (state, action) => {
      if (action.payload) {
        state.emailCardColor = action.payload;
      } else {
        state.emailCardColor = initialStateValue.emailCardColor
      }
    },
    setEmailLinkColor: (state, action) => {
      if (action.payload) {
        state.emailLinkColor = action.payload;
      } else {
        state.emailLinkColor = initialStateValue.emailLinkColor
      }
    },
    setEmailButtonBackgroundColor: (state, action) => {
      if (action.payload) {
        state.emailButtonBackgroundColor = action.payload;
      } else {
        state.emailButtonBackgroundColor = initialStateValue.emailButtonBackgroundColor
      }
    },
    setEmailButtonTextColor: (state, action) => {
      if (action.payload) {
        state.emailButtonTextColor = action.payload;
      } else {
        state.emailButtonTextColor = initialStateValue.emailButtonTextColor
      }
    },
    setEmailLogoAlignment: (state, action) => {
      if (action.payload) {
        state.emailLogoAlignment = action.payload;
      } else {
        state.emailLogoAlignment = initialStateValue.emailLogoAlignment
      }
    },
    setEmailLanguage: (state, action) => {
      if (action.payload) {
        state.emailLanguage = action.payload;
      } else {
        state.emailLanguage = initialStateValue.emailLanguage
      }
    },
    setEmailIndustry: (state, action) => {
      if (action.payload) {
        state.emailIndustry = action.payload;
      } else {
        state.emailIndustry = initialStateValue.emailIndustry
      }
    },
    setEmailTone: (state, action) => {
      if (action.payload) {
        state.emailTone = action.payload;
      } else {
        state.emailTone = initialStateValue.emailTone
      }
    },
    setEmailGenerationLoading: (state, action) => {
      if (action.payload) {
        state.emailGenerationLoading = action.payload;
      } else {
        state.emailGenerationLoading = initialStateValue.emailGenerationLoading
      }
    },
    setWelcomeEmailSubject: (state, action) => {
      if (action.payload) {
        state.welcomeEmailSubject = action.payload;
      } else {
        state.welcomeEmailSubject = initialStateValue.welcomeEmailSubject
      }
    },
    setWelcomeEmailBody: (state, action) => {
      if (action.payload) {
        state.welcomeEmailBody = action.payload;
      } else {
        state.welcomeEmailBody = initialStateValue.welcomeEmailBody
      }
    },
    setVerificationEmailSubject: (state, action) => {
      if (action.payload) {
        state.verificationEmailSubject = action.payload;
      } else {
        state.verificationEmailSubject = initialStateValue.verificationEmailSubject
      }
    },
    setVerificationEmailBody: (state, action) => {
      if (action.payload) {
        state.verificationEmailBody = action.payload;
      } else {
        state.verificationEmailBody = initialStateValue.verificationEmailBody
      }
    },
    setResetPasswordEmailSubject: (state, action) => {
      if (action.payload) {
        state.resetPasswordEmailSubject = action.payload;
      } else {
        state.resetPasswordEmailSubject = initialStateValue.resetPasswordEmailSubject
      }
    },
    setResetPasswordEmailBody: (state, action) => {
      if (action.payload) {
        state.resetPasswordEmailBody = action.payload;
      } else {
        state.resetPasswordEmailBody = initialStateValue.resetPasswordEmailBody
      }
    },
    setForgotPasswordEmailSubject: (state, action) => {
      if (action.payload) {
        state.forgotPasswordEmailSubject = action.payload;
      } else {
        state.forgotPasswordEmailSubject = initialStateValue.forgotPasswordEmailSubject
      }
    },
    setForgotPasswordEmailBody: (state, action) => {
      if (action.payload) {
        state.forgotPasswordEmailBody = action.payload;
      } else {
        state.forgotPasswordEmailBody = initialStateValue.forgotPasswordEmailBody
      }
    },
    setDeleteAccountEmailSubject: (state, action) => {
      if (action.payload) {
        state.deleteAccountEmailSubject = action.payload;
      } else {
        state.deleteAccountEmailSubject = initialStateValue.deleteAccountEmailSubject
      }
    },
    setDeleteAccountEmailBody: (state, action) => {
      if (action.payload) {
        state.deleteAccountEmailBody = action.payload;
      } else {
        state.deleteAccountEmailBody = initialStateValue.deleteAccountEmailBody
      }
    },
    setAddEmailSubject: (state, action) => {
      if (action.payload) {
        state.addEmailSubject = action.payload;
      } else {
        state.addEmailSubject = initialStateValue.addEmailSubject
      }
    },
    setAddEmailBody: (state, action) => {
      if (action.payload) {
        state.addEmailBody = action.payload;
      } else {
        state.addEmailBody = initialStateValue.addEmailBody
      }
    },
    setSocialLinkFacebook: (state, action) => {
      if (action.payload) {
        state.socialLinkFacebook = action.payload;
      } else {
        state.socialLinkFacebook = initialStateValue.socialLinkFacebook
      }
    },
    setSocialLinkTwitter: (state, action) => {
      if (action.payload) {
        state.socialLinkTwitter = action.payload;
      } else {
        state.socialLinkTwitter = initialStateValue.socialLinkTwitter
      }
    },
    setSocialLinkLinkedin: (state, action) => {
      if (action.payload) {
        state.socialLinkLinkedin = action.payload;
      } else {
        state.socialLinkLinkedin = initialStateValue.socialLinkLinkedin
      }
    },
    setSocialLinkInstagram: (state, action) => {
      if (action.payload) {
        state.socialLinkInstagram = action.payload;
      } else {
        state.socialLinkInstagram = initialStateValue.socialLinkInstagram
      }
    },
    setSocialLinkYoutube: (state, action) => {
      if (action.payload) {
        state.socialLinkYoutube = action.payload;
      } else {
        state.socialLinkYoutube = initialStateValue.socialLinkYoutube
      }
    },
    setSocialLinkDiscord: (state, action) => {
      if (action.payload) {
        state.socialLinkDiscord = action.payload;
      } else {
        state.socialLinkDiscord = initialStateValue.socialLinkDiscord
      }
    },
    setLinkPrivacyPolicy: (state, action) => {
      if (action.payload) {
        state.linkPrivacyPolicy = action.payload;
      } else {
        state.linkPrivacyPolicy = initialStateValue.linkPrivacyPolicy
      }
    },
    setLinkTermAndCondition: (state, action) => {
      if (action.payload) {
        state.linkTermAndCondition = action.payload;
      } else {
        state.linkTermAndCondition = initialStateValue.linkTermAndCondition
      }
    },
    setLinkUnsubscribe: (state, action) => {
      if (action.payload) {
        state.linkUnsubscribe = action.payload;
      } else {
        state.linkUnsubscribe = initialStateValue.linkUnsubscribe
      }
    },
    setCopyrightAddress: (state, action) => {
      if (action.payload) {
        state.copyrightAddress = action.payload;
      } else {
        state.copyrightAddress = initialStateValue.copyrightAddress
      }
    },
  },
})

export const {
  resetEmailTemplateValues,
  setEmailFont,
  setEmailSelectedTemplate,
  setEmailSelectedTab,
  setEmailBrandColor,
  setEmailTextColor,
  setEmailBackgroundColor,
  setEmailCardColor,
  setEmailLinkColor,
  setEmailButtonBackgroundColor,
  setEmailButtonTextColor,
  setEmailLogoAlignment,
  setEmailLanguage,
  setEmailIndustry,
  setEmailTone,
  setEmailGenerationLoading,
  setWelcomeEmailSubject,
  setWelcomeEmailBody,
  setVerificationEmailSubject,
  setVerificationEmailBody,
  setResetPasswordEmailSubject,
  setResetPasswordEmailBody,
  setForgotPasswordEmailSubject,
  setForgotPasswordEmailBody,
  setDeleteAccountEmailSubject,
  setDeleteAccountEmailBody,
  setAddEmailSubject,
  setAddEmailBody,
  setSocialLinkFacebook,
  setSocialLinkTwitter,
  setSocialLinkLinkedin,
  setSocialLinkInstagram,
  setSocialLinkYoutube,
  setSocialLinkDiscord,
  setLinkPrivacyPolicy,
  setLinkTermAndCondition,
  setLinkUnsubscribe,
  setCopyrightAddress,
} = emailTemplateValuesSlice.actions

export default emailTemplateValuesSlice.reducer
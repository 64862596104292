import React from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setUrl } from "../store/commonValues";

function Step1(props: {
  onSubmitClick: () => void;
}) {
  const { url } = useAppSelector(state => state.commonValues)
  const appDispatch = useAppDispatch();
  return (
    <>
      {/* landing page */}
      <div className="landing-page" id="step_1">
        <h1>
          Build <span>AI-Powered</span> branded Identity Experience page
        </h1>
        <p>
          With just a few clicks, create and personalize the UI of the
          authentication page for your brand's application and app.
        </p>
        <form>
          <div className="form-control">
            <input
              type="url"
              className="large url-input"
              placeholder="Enter your website URL"
              value={url}
              onChange={(e) => {
                console.log("is typing: ", e.target.value)
                console.log("current url value is: ", url)
                appDispatch(setUrl(e.target.value));
              }}
            ></input>
            <input
              className="btn btn-primary large"
              type="submit"
              value="Generate"
              onClick={props.onSubmitClick}
            />
          </div>
        </form>
      </div>
      {/* landing page */}

      {/* step2 */}
      {/* already developed */}
      {/* step2 */}

      {/* step3 */}
      {/* <div>
        <div className="page-header">
          <h1 className="lr-tab-header" id="head_LoginScreen">
            Select a template
          </h1>
          <div className="panel-heading">
            <p className="idx-header pb-3" id="p_LoginScreen_Description">
              We generated a few templates inspired by your website. Please pick
              a template and then customize it.
            </p>
          </div>
        </div>
        <div className="page-body">
          <div className="grid-33">
            <div className="card light-gray">
              <h3>Template 1</h3>
            </div>
            <div className="card light-gray">
              <h3>Template 2</h3>
            </div>
            <div className="card light-gray">
              <h3>Template 3</h3>
            </div>
          </div>
        </div>
        <div className="page-footer">
          <button className="btn btn-ghost">Previous</button>
          <button className="btn btn-primary">Continue</button>
        </div>
      </div> */}
      {/* step3 */}

      {/* step4 */}
      {/* <div>
        <div className="page-header">
          <h1 className="lr-tab-header" id="head_LoginScreen">
            Customize your template
          </h1>
          <div className="panel-heading">
            <p className="idx-header pb-3" id="p_LoginScreen_Description">
              Please review your template and customize the appearance as
              needed.
            </p>
          </div>
        </div>
        <div className="page-body">
          <div className="tab-warpper">
            <input
              className="radio"
              id="one"
              name="group"
              type="radio"
              checked
              readOnly
            />
            <input className="radio" id="two" name="group" type="radio" />
            <input className="radio" id="three" name="group" type="radio" />
            <input className="radio" id="four" name="group" type="radio" />
            <div className="tabs">
              <div>
                <label className="tab" id="one-tab" htmlFor="one">
                  Sign in
                </label>
                <label className="tab" id="two-tab" htmlFor="two">
                  Sign up
                </label>
                <label className="tab" id="three-tab" htmlFor="three">
                  Forgot password
                </label>
                <label className="tab" id="four-tab" htmlFor="four">
                  MFA
                </label>
              </div>
              <div className="btn-group">
                <button className="btn btn-primary customization">
                  Customization
                </button>
              </div>
            </div>
            <div className="panels">
              <div className="panel" id="one-panel">
                Add Sign in screen here
              </div>
              <div className="panel" id="two-panel">
                Add Sign up screen here
              </div>
              <div className="panel" id="three-panel">
                Add Forgot password screen here
              </div>
              <div className="panel" id="four-panel">
                Add MFA here
              </div>
            </div>
          </div>
        </div>
        <div className="page-footer">
          <button className="btn btn-ghost">Previous</button>
          <button className="btn btn-primary">Continue</button>
        </div>
      </div> */}
      {/* step4 */}

      {/* step5 */}
      {/* <div>
        <div className="page-header">
          <h1 className="lr-tab-header" id="head_LoginScreen">
            Customize your template
          </h1>
          <div className="panel-heading">
            <p className="idx-header pb-3" id="p_LoginScreen_Description">
              Please review your template and customize the appearance as
              needed.
            </p>
          </div>
        </div>
        <div className="page-body">
          <div className="tab-warpper">
            <input
              className="radio"
              id="one"
              name="group"
              type="radio"
              checked
              readOnly
            />
            <input className="radio" id="two" name="group" type="radio" />
            <input className="radio" id="three" name="group" type="radio" />
            <input className="radio" id="four" name="group" type="radio" />
            <div className="tabs">
              <div>
                <label className="tab" id="one-tab" htmlFor="one">
                  Sign in
                </label>
                <label className="tab" id="two-tab" htmlFor="two">
                  Sign up
                </label>
                <label className="tab" id="three-tab" htmlFor="three">
                  Forgot password
                </label>
                <label className="tab" id="four-tab" htmlFor="four">
                  MFA
                </label>
              </div>
              <div className="btn-group">
                <button className="btn btn-primary customization">Theme</button>
                <button className="btn btn-primary customization">
                  Try Sign in
                </button>
              </div>
            </div>
            <div className="panels">
              <div className="panel" id="one-panel">
                Add Sign in screen here
              </div>
              <div className="panel" id="two-panel">
                Add Sign up screen here
              </div>
              <div className="panel" id="three-panel">
                Add Forgot password screen here
              </div>
              <div className="panel" id="four-panel">
                Add MFA here
              </div>
            </div>
          </div>
        </div>
        <div className="page-footer">
          <button className="btn btn-ghost">Back to themes</button>
          <button className="btn btn-primary">Try Sign in</button>
        </div>
      </div> */}
      {/* step5 */}

      {/* step6 */}
      {/* <div>
        <div className="page-header">
          <h1 className="lr-tab-header" id="head_LoginScreen">
            Customize your template
          </h1>
          <div className="panel-heading">
            <p className="idx-header pb-3" id="p_LoginScreen_Description">
              Please review your template and customize the appearance as
              needed.
            </p>
          </div>
        </div>
        <div className="page-body">
          <div className="customization-wrapper">
            <div className="accordion-warpper">
              <div className="sub-header">
                <h2>Customization</h2>
              </div>
              <div className="accordion-tabs">
                <div className="accordion-tab">
                  <input type="radio" id="rd1" name="rd" />
                  <label className="accordion-tab-label" htmlFor="rd1">
                    Body
                  </label>
                  <div className="accordion-tab-content">
                    <div className="customization-form">
                      <div className="form-control ">
                        <label htmlFor="backgroundImage">
                          Background image
                        </label>
                        <input type="url" id="backgroundImage" />
                      </div>
                      <div className="form-control ">
                        <label htmlFor="backgroundColor">
                          Background Color
                        </label>
                        <div className="color">
                          <span className="color-value">#e7baba</span>
                          <input type="color" id="backgroundColor" />
                        </div>
                      </div>
                      <div className="form-control ">
                        <label htmlFor="bodyBrandColor">Brand Color</label>
                        <div className="color">
                          <span className="color-value">#e7baba</span>
                          <input type="color" id="bodyBrandColor" />
                        </div>
                      </div>
                      <div className="form-control ">
                        <label htmlFor="textcolor">Text Color</label>
                        <div className="color">
                          <span className="color-value">#e7baba</span>
                          <input type="color" id="textcolor" />
                        </div>
                      </div>
                      <div className="form-control ">
                        <label htmlFor="fontfamily">Font Family</label>
                        <select name="" id="fontfamily">
                          <option value="1">Test</option>
                          <option value="2">Test</option>
                          <option value="3">Test</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-tab">
                  <input type="radio" id="rd2" name="rd" />
                  <label className="accordion-tab-label" htmlFor="rd2">
                    Content
                  </label>
                  <div className="accordion-tab-content">
                    <h3>Sign in</h3>
                    <div className="customization-form">
                      <div className="form-control">
                        <label htmlFor="pagetitle">Page Title</label>
                        <input type="text" id="pagetitle" />
                      </div>
                      <div className="form-control">
                        <label htmlFor="pagesubtitle">Page Subtitle</label>
                        <input type="text" id="pagesubtitle" />
                      </div>
                    </div>
                    <h3>Sign up</h3>
                    <div className="customization-form">
                      <div className="form-control">
                        <label htmlFor="pagetitle">Page Title</label>
                        <input type="text" id="pagetitle" />
                      </div>
                      <div className="form-control">
                        <label htmlFor="pagesubtitle">Page Subtitle</label>
                        <input type="text" id="pagesubtitle" />
                      </div>
                    </div>
                    <h3>Forgot password</h3>
                    <div className="customization-form">
                      <div className="form-control">
                        <label htmlFor="pagetitle">Page Title</label>
                        <input type="text" id="pagetitle" />
                      </div>
                      <div className="form-control">
                        <label htmlFor="pagesubtitle">Page Subtitle</label>
                        <input type="text" id="pagesubtitle" />
                      </div>
                    </div>
                    <h3>MFA</h3>
                    <div className="customization-form">
                      <div className="form-control">
                        <label htmlFor="pagetitle">Page Title</label>
                        <input type="text" id="pagetitle" />
                      </div>
                      <div className="form-control">
                        <label htmlFor="pagesubtitle">Page Subtitle</label>
                        <input type="text" id="pagesubtitle" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-tab">
                  <input type="radio" id="rd3" name="rd" />
                  <label className="accordion-tab-label" htmlFor="rd3">
                    Input Fields
                  </label>
                  <div className="accordion-tab-content">
                    <div className="customization-form">
                      <div className="form-control ">
                        <label htmlFor="backgroundcolor">
                          Background color
                        </label>
                        <div className="color">
                          <span className="color-value">#e7baba</span>
                          <input type="color" id="backgroundcolor" />
                        </div>
                      </div>
                      <div className="form-control ">
                        <label htmlFor="hovercolor">Border color</label>
                        <div className="color">
                          <span className="color-value">#e7baba</span>
                          <input type="color" id="hovercolor" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-tab">
                  <input type="radio" id="rd4" name="rd" />
                  <label className="accordion-tab-label" htmlFor="rd4">
                    Links
                  </label>
                  <div className="accordion-tab-content">
                    <div className="customization-form">
                      <div className="form-control ">
                        <label htmlFor="linkcolor">Link color</label>
                        <div className="color">
                          <span className="color-value">#e7baba</span>
                          <input type="color" id="linkcolor" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-tab">
                  <input type="radio" id="rd5" name="rd" />
                  <label className="accordion-tab-label" htmlFor="rd5">
                    Submit button
                  </label>
                  <div className="accordion-tab-content">
                    <div className="customization-form">
                      <div className="form-control ">
                        <label htmlFor="buttonbackgroundcolor">
                          Background color
                        </label>
                        <div className="color">
                          <span className="color-value">#e7baba</span>
                          <input type="color" id="buttonbackgroundcolor" />
                        </div>
                      </div>
                      <div className="form-control ">
                        <label htmlFor="buttonTextcolor">Text color</label>
                        <div className="color">
                          <span className="color-value">#e7baba</span>
                          <input type="color" id="buttonTextcolor" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-tab">
                  <input type="radio" id="rd6" name="rd" />
                  <label className="accordion-tab-label" htmlFor="rd6">
                    Social button layout
                  </label>
                  <div className="accordion-tab-content">
                    <div className="customization-form">
                      <div className="btn-group action-buttons">
                        <div>
                          <button className="btn-desktopview active">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17 2H7C5.89543 2 5 2.89543 5 4V20C5 21.1046 5.89543 22 7 22H17C18.1046 22 19 21.1046 19 20V4C19 2.89543 18.1046 2 17 2Z"
                                stroke="#41484E"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8 6H16"
                                stroke="#41484E"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              />
                              <path
                                d="M8 9H16"
                                stroke="#41484E"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              />
                            </svg>
                          </button>
                          <button className="btn-mobileview">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7 22L17 22C18.1046 22 19 21.1046 19 20L19 4C19 2.89543 18.1046 2 17 2L7 2C5.89543 2 5 2.89543 5 4L5 20C5 21.1046 5.89543 22 7 22Z"
                                stroke="#41484E"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M16 18L8 18"
                                stroke="#41484E"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              />
                              <path
                                d="M16 15L8 15"
                                stroke="#41484E"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="iframe">remove this and add iframe</div>
          </div>
        </div>
        <div className="page-footer">
          <button className="btn btn-ghost">Previous</button>
          <button className="btn btn-primary">Continue</button>
        </div>
      </div> */}
      {/* step6 */}
      {/* loading spinner */}
      {/* <div className="loading-spinner">
        <div className="learnMore">
          <p>
            <strong>
              <span>Retrieve company logo image</span>
              <span>Capture preferred font family</span>
              <span>Extract primary brand color</span>
              <span>Ensure consistent brand representation</span>
              <span>Enhance user experience with branding</span>
            </strong>
          </p>
        </div>
        <div className="progress">
          <div className="bar" style={{ width: "35%" }}>
            <p className="percent">35%</p>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Step1;

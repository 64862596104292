import SelectablePreviewCard from "../components/selectablePreviewCard";
import { CurrentTabEnum, navigateBack, navigateForward, setCurrentTab, setViewPort } from "../store/commonValues";
import { setEmailSelectedTemplate } from "../store/emailTemplateValues";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setLoginSelectedTemplate } from "../store/loginTemplateValues";
import { setSmsSelectedTemplate } from "../store/smsTemplateValues";

function Step3(props: {
  iframePreviewLoad: (
    templateName?: string,
    page?: string,
    action?: string
  ) => string;
}) {
  const {
    currentTab,
    viewPort
  } = useAppSelector(state => state.commonValues);
  const {
    loginSelectedTab,
    loginTemplates,
    loginSelectedTemplate,
  } = useAppSelector(state => state.loginTemplateValues);
  const {
    emailSelectedTab,
    emailTemplates,
    emailSelectedTemplate,
  } = useAppSelector(state => state.emailTemplateValues);
  const {
    smsSelectedTab,
    smsTemplates,
    smsSelectedTemplate,
  } = useAppSelector(state => state.smsTemplateValues);
  const dispatch = useAppDispatch();
  return (
    <div id="step2">
      <div className="page-header">
        <h1 className="lr-tab-header" id="head_LoginScreen">
          Select a template
        </h1>
        <div className="panel-heading">
          <p className="idx-header pb-3" id="p_LoginScreen_Description">
            We generated a few templates inspired by your website. Please pick a
            template and then customize it.
          </p>
        </div>
      </div>

      <div className="page-body">
        <div className="tab-warpper">
          <input
            className="radio"
            id="one"
            name="group"
            type="radio"
            defaultChecked={
              currentTab === CurrentTabEnum.LOGIN && [1, 2, 3, 4].indexOf(loginSelectedTab) !== -1 ? true : false
            }
            onClick={() => {
              dispatch(setCurrentTab(CurrentTabEnum.LOGIN))
            }}
          />
          <input
            className="radio"
            id="two"
            name="group"
            type="radio"
            defaultChecked={
              currentTab === CurrentTabEnum.EMAIL && [5, 6, 7, 8, 9, 10].indexOf(emailSelectedTab) !== -1 ? true : false
            }
            onClick={() => {
              dispatch(setCurrentTab(CurrentTabEnum.EMAIL))
            }}
          />
          <input
            className="radio"
            id="three"
            name="group"
            type="radio"
            defaultChecked={
              currentTab === CurrentTabEnum.SMS && [11, 12, 13, 14].indexOf(smsSelectedTab) !== -1 ? true : false
            }
            onClick={() => {
              dispatch(setCurrentTab(CurrentTabEnum.SMS))
            }}
          />

          <div className="tabs">
            <div>
              <label className="tab" id="one-tab" htmlFor="one">
                Login
              </label>
              <label className="tab" id="two-tab" htmlFor="two">
                Email
              </label>
              <label className="tab" id="three-tab" htmlFor="three">
                SMS
              </label>
            </div>
            <div className="btn-group action-buttons">
              <div>
                {currentTab !== CurrentTabEnum.SMS ? (
                  <>
                    <button
                      className={
                        `btn-desktopview` +
                        (viewPort === 1070 ? " active" : "")
                      }
                      onClick={() => {
                        dispatch(setViewPort(1070));
                      }}
                    >
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 3.5H4C2.89543 3.5 2 4.39543 2 5.5V15.5C2 16.6046 2.89543 17.5 4 17.5H20C21.1046 17.5 22 16.6046 22 15.5V5.5C22 4.39543 21.1046 3.5 20 3.5Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 21.5H16"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 17.5V21.5"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <button
                      className={
                        `btn-mobileview` + (viewPort === 418 ? " active" : "")
                      }
                      onClick={() => {
                        dispatch(setViewPort(418));
                      }}
                    >
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17 2.5H7C5.89543 2.5 5 3.39543 5 4.5V20.5C5 21.6046 5.89543 22.5 7 22.5H17C18.1046 22.5 19 21.6046 19 20.5V4.5C19 3.39543 18.1046 2.5 17 2.5Z"
                          stroke="#41484E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 18.5H12.01"
                          stroke="#41484E"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </>
                ) : ""}
              </div>
            </div>
          </div>
          <div className="panels">
            <div className="panel" id="one-panel">
              <div
                className={`grid-50 ${viewPort === 1070 ? "desktop" : "mobile"
                  }`}
              >
                {loginTemplates.map((templateName, ind, templates) => (
                  <SelectablePreviewCard
                    ind={ind}
                    key={ind}
                    templates={templates}
                    tab={`login`}
                    previewSrc={props.iframePreviewLoad(
                      templateName,
                      "login",
                      "login"
                    )}
                    isSelected={loginSelectedTemplate === templateName}
                    viewPort={viewPort}
                    setViewPort={(port) => dispatch(setViewPort(port))}
                    onClick={() => {
                      dispatch(setLoginSelectedTemplate(templateName));
                    }}
                  />
                ))}
              </div>
            </div>
            <div className="panel" id="two-panel">
              <div
                className={`grid-50 ${viewPort === 1070 ? "desktop" : "mobile"
                  }`}
              >
                {emailTemplates.map((templateName, ind, templates) => (
                  <SelectablePreviewCard
                    ind={ind}
                    key={ind}
                    templates={templates}
                    tab={`email`}
                    previewSrc={props.iframePreviewLoad(
                      templateName,
                      "welcome",
                      "email"
                    )}
                    isSelected={emailSelectedTemplate === templateName}
                    viewPort={viewPort}
                    setViewPort={(port) => dispatch(setViewPort(port))}
                    onClick={() => {
                      dispatch(setEmailSelectedTemplate(templateName));
                    }}
                  />
                ))}
              </div>
            </div>
            <div className="panel" id="three-panel">
              <div
                className={`grid-50 ${viewPort === 1070 ? "desktop" : "mobile"
                  }`}
              >
                {smsTemplates.map((templateName, ind, templates) => (
                  <SelectablePreviewCard
                    ind={ind}
                    key={ind}
                    templates={templates}
                    tab={`sms`}
                    previewSrc={props.iframePreviewLoad(
                      templateName,
                      "welcome-sms",
                      "sms"
                    )}
                    isSelected={smsSelectedTemplate === templateName}
                    viewPort={viewPort}
                    setViewPort={(port) => dispatch(setViewPort(port))}
                    onClick={() => {
                      dispatch(setSmsSelectedTemplate(templateName));
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-footer">
        <button
          className="btn btn-ghost"
          onClick={() => {
            dispatch(navigateBack());
          }}
        >
          Previous
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            dispatch(navigateForward());
          }}
        >
          Customize
        </button>
      </div>
    </div>
  );
}

export default Step3;

import { createSlice } from '@reduxjs/toolkit'

interface colorPaletteInterface {
  brandColor: string;
  bodyColor: string;
  cardColor: string;
  textColor: string;
}

export const enum CurrentTabEnum {
  LOGIN = "login",
  EMAIL = "email",
  SMS = "sms"
}

export interface commonValuesInterface {
  url: string,
  currentStep: number,
  selectedLogo: string,
  viewPort: number,
  selectedColorPalette: number,
  // progress: number,
  currentTab: CurrentTabEnum,
  logos: string[],
  logoUploadFile: string | null,
  logoUploadSrc: string,
  logoUploadColorPalettes: colorPaletteInterface[],
  colorPalettes: colorPaletteInterface[]
}

const initialStateValue: commonValuesInterface = {
  url: "",
  currentStep: 0,
  selectedLogo: "",
  viewPort: 418,
  selectedColorPalette: 0,
  // progress: 0,
  currentTab: CurrentTabEnum.LOGIN,
  logos: [],
  logoUploadFile: null,
  logoUploadSrc: "",
  logoUploadColorPalettes: [],
  colorPalettes: []
};

export const commonValuesSlice = createSlice({
  name: 'commonValues',
  initialState: initialStateValue,
  reducers: {
    resetCommonValues: (state) => {
      state = initialStateValue;
    },
    setUrl: (state, action) => {
      if (action.payload) {
        state.url = action.payload;
      } else {
        state.url = initialStateValue.url
      }
    },
    setStep: (state, action) => {
      if (action.payload) {
        state.currentStep = action.payload;
      } else {
        state.currentStep = initialStateValue.currentStep
      }
    },
    navigateBack: (state) => {
      // if coming back to url input, clear all existing states.
      if (state.currentStep === 1) {
        state = initialStateValue;
      } else {
        state.currentStep = state.currentStep - 1;
      }
    },
    navigateForward: (state) => {
      state.currentStep = state.currentStep + 1;
    },
    setLoadingStep: (state) => {
      state.currentStep = -1;
    },
    setSelectedLogo: (state, action) => {
      if (action.payload) {
        state.selectedLogo = action.payload;
      } else {
        state.selectedLogo = initialStateValue.selectedLogo
      }
    },
    setViewPort: (state, action) => {
      if (action.payload) {
        state.viewPort = action.payload;
      } else {
        state.viewPort = initialStateValue.viewPort
      }
    },
    setSelectedColorPalette: (state, action) => {
      if (action.payload) {
        state.selectedColorPalette = action.payload;
      } else {
        state.selectedColorPalette = initialStateValue.selectedColorPalette
      }
    },
    setCurrentTab: (state, action) => {
      if (action.payload) {
        state.currentTab = action.payload;
      } else {
        state.currentTab = initialStateValue.currentTab
      }
    },
    setLogos: (state, action) => {
      if (action.payload) {
        state.logos = action.payload;
      } else {
        state.logos = initialStateValue.logos
      }
    },
    setLogoUploadFile: (state, action) => {
      if (action.payload) {
        state.logoUploadFile = action.payload;
      } else {
        state.logoUploadFile = initialStateValue.logoUploadFile
      }
    },
    setLogoUploadSrc: (state, action) => {
      if (action.payload) {
        state.logoUploadSrc = action.payload;
      } else {
        state.logoUploadSrc = initialStateValue.logoUploadSrc
      }
    },
    setLogoUploadColorPalettes: (state, action) => {
      if (action.payload) {
        state.logoUploadColorPalettes = action.payload;
      } else {
        state.logoUploadColorPalettes = initialStateValue.logoUploadColorPalettes
      }
    },
    setColorPalettes: (state, action) => {
      if (action.payload) {
        state.colorPalettes = action.payload;
      } else {
        state.colorPalettes = initialStateValue.colorPalettes
      }
    },
  },
})

export const {
  resetCommonValues,
  setUrl,
  setStep,
  navigateBack,
  navigateForward,
  setLoadingStep,
  setSelectedLogo,
  setViewPort,
  setSelectedColorPalette,
  setCurrentTab,
  setLogos,
  setLogoUploadFile,
  setLogoUploadSrc,
  setLogoUploadColorPalettes,
  setColorPalettes
} = commonValuesSlice.actions

export default commonValuesSlice.reducer
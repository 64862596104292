import React from "react";

function InputCard(props: {
  label: string;
  placeholder: string;
  type: string;
  value: string;
  setValue: (value: string) => void;
}) {
  return (
    <div className="form-control">
      <span>{props.label}</span>
      <input
        type={props.type}
        placeholder={props.placeholder}
        className="large"
        value={props.value}
        onChange={(e) => {
          props.setValue(e.target.value);
        }}
      />
    </div>
  );
}

export default InputCard;

import React, { useState, useEffect } from "react";
import "./App.css";

import { useAppSelector, useAppDispatch } from "./store/hooks";

// import { Oval } from "react-loader-spinner";
import Step1 from "./containers/step1url";
import Step2 from "./containers/step2logo";
import Step3 from "./containers/step3template";
import Step5 from "./containers/step5customization";

import logoDark from "./images/logo-dark.svg";
import fontList from "./assets/fontList";
import {
  navigateBack,
  setColorPalettes,
  setLoadingStep,
  setLogoUploadColorPalettes,
  setLogoUploadSrc,
  setLogos,
  setSelectedColorPalette,
  setSelectedLogo,
  setStep,
} from "./store/commonValues";
import {
  setLoginBackgroundColor,
  setLoginBrandColor,
  setLoginButtonBackgroundColor,
  setLoginButtonTextColor,
  setLoginCardColor,
  setLoginFont,
  setLoginInputBackgroundColor,
  setLoginInputBorderColor,
  setLoginLanguage,
  setLoginLinkColor,
  setLoginTemplateContent,
  setLoginTextColor,
  setLoginTranslationData
} from "./store/loginTemplateValues";
import {
  setAddEmailBody,
  setAddEmailSubject,
  setCopyrightAddress,
  setDeleteAccountEmailBody,
  setDeleteAccountEmailSubject,
  setEmailBackgroundColor,
  setEmailBrandColor,
  setEmailButtonBackgroundColor,
  setEmailButtonTextColor,
  setEmailCardColor,
  setEmailFont,
  setEmailGenerationLoading,
  setEmailIndustry,
  setEmailLanguage,
  setEmailLinkColor,
  setEmailTextColor,
  setForgotPasswordEmailBody,
  setForgotPasswordEmailSubject,
  setLinkPrivacyPolicy,
  setLinkTermAndCondition,
  setResetPasswordEmailBody,
  setResetPasswordEmailSubject,
  setSocialLinkDiscord,
  setSocialLinkFacebook,
  setSocialLinkInstagram,
  setSocialLinkLinkedin,
  setSocialLinkTwitter,
  setSocialLinkYoutube,
  setVerificationEmailBody,
  setVerificationEmailSubject,
  setWelcomeEmailBody,
  setWelcomeEmailSubject
} from "./store/emailTemplateValues";
import {
  setPhoneNumberChangeSmsBody,
  setResetPasswordSmsBody,
  setSmsLanguage,
  setVerificationSmsBody,
  setWelcomeSmsBody
} from "./store/smsTemplateValues";
// import languagesList from "./assets/lanaguagesList";

function App() {
  // Common variables
  const {
    url,
    selectedLogo,
    selectedColorPalette,
    colorPalettes,
    logoUploadColorPalettes,
    logoUploadFile,
    logos,
    currentStep,
  } = useAppSelector(state => state.commonValues);
  const {
    loginBackgroundImage,
    loginBackgroundColor,
    loginBrandColor,
    loginCardColor,
    loginTextColor,
    loginFont,
    loginTemplateContent,
    loginLinkColor,
    loginInputBackgroundColor,
    loginInputBorderColor,
    loginButtonBackgroundColor,
    loginButtonTextColor,
    loginSocialButtonLayout,
    loginLanguage,
    loginTranslationData,
  } = useAppSelector(state => state.loginTemplateValues);
  const {
    emailLanguage,
    emailIndustry,
    emailTone,
    welcomeEmailSubject,
    welcomeEmailBody,
    verificationEmailSubject,
    verificationEmailBody,
    resetPasswordEmailSubject,
    resetPasswordEmailBody,
    forgotPasswordEmailSubject,
    forgotPasswordEmailBody,
    deleteAccountEmailSubject,
    deleteAccountEmailBody,
    addEmailSubject,
    addEmailBody,
    emailLogoAlignment,
    emailBackgroundColor,
    emailBrandColor,
    emailCardColor,
    emailTextColor,
    emailFont,
    emailLinkColor,
    emailButtonBackgroundColor,
    emailButtonTextColor,
    socialLinkFacebook,
    socialLinkTwitter,
    socialLinkLinkedin,
    socialLinkInstagram,
    socialLinkYoutube,
    socialLinkDiscord,
    linkPrivacyPolicy,
    linkTermAndCondition,
    linkUnsubscribe,
    copyrightAddress
  } = useAppSelector(state => state.emailTemplateValues);
  const {
    welcomeSmsBody,
    verificationSmsBody,
    resetPasswordSmsBody,
    phoneNumberChangeSmsBody,
    smsLanguage,
  } = useAppSelector(state => state.smsTemplateValues);
  const dispatch = useAppDispatch();

  const [progress, setProgress] = useState(0);
  const currentYear = new Date().getFullYear();
  const baseUrl =
    "https://2bma7mkvzrjcqennxx7it6lnu40dcigt.lambda-url.us-east-1.on.aws";

  interface logoResponse {
    statusCode: number;
    body: {
      industry: string;
      termsAndConditions: string;
      copyrightText: string;
      privacyPolicy: string;
      socialLinks: any;
      language: string;
      logoSrcs: string[];
      fonts: string[];
      palettes?: {
        brandColor: string;
        bodyColor: string;
        cardColor: string;
        textColor: string;
      }[];
    };
  }

  const generateAIEmail = (iframePage: string) => {
    dispatch(setEmailGenerationLoading(true));
    let requestQuery = `endpoint=${encodeURIComponent(url)}`;
    requestQuery += `&language=${encodeURIComponent(emailLanguage)}`;
    switch (iframePage) {
      case "welcome":
        requestQuery += `&genWelcomeEmail=true`;
        break;
      case "verification":
        requestQuery += `&genVerificationEmail=true`;
        break;
      case "forgot-password":
        requestQuery += `&genForgotPasswordEmail=true`;
        break;
      case "delete-account":
        requestQuery += `&genDeleteAccountEmail=true`;
        break;
      case "add-email":
        requestQuery += `&genAddEmail=true`;
        break;
      case "reset-password":
        requestQuery += `&genResetPasswordEmail=true`;
        break;
    }
    requestQuery += `&industry=${encodeURIComponent(emailIndustry)}`;
    requestQuery += `&tone=${encodeURIComponent(emailTone)}`;
    fetch(baseUrl + `/emailTemplates?${requestQuery}`, {
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((resp) => {
        return resp.json();
      })
      .then((Response) => {
        switch (iframePage) {
          case "welcome":
            dispatch(setWelcomeEmailSubject(
              Response.welcomeEmail
                ? Response.welcomeEmail.subject
                : welcomeEmailSubject
            ));
            dispatch(setWelcomeEmailBody(
              Response.welcomeEmail
                ? Response.welcomeEmail.body
                : welcomeEmailBody
            ));
            break;
          case "verification":
            dispatch(setVerificationEmailSubject(
              Response.verificationEmail
                ? Response.verificationEmail.subject
                : verificationEmailSubject
            ));
            dispatch(setVerificationEmailBody(
              Response.verificationEmail
                ? Response.verificationEmail.body
                : verificationEmailBody
            ));
            break;
          case "reset-password":
            dispatch(setResetPasswordEmailSubject(
              Response.resetPasswordEmail
                ? Response.resetPasswordEmail.subject
                : resetPasswordEmailSubject
            ));
            dispatch(setResetPasswordEmailBody(
              Response.resetPasswordEmail
                ? Response.resetPasswordEmail.body
                : resetPasswordEmailBody
            ));
            break;
          case "forgot-password":
            dispatch(setForgotPasswordEmailSubject(
              Response.forgotPasswordEmail
                ? Response.forgotPasswordEmail.subject
                : forgotPasswordEmailSubject
            ));
            dispatch(setForgotPasswordEmailBody(
              Response.forgotPasswordEmail
                ? Response.forgotPasswordEmail.body
                : forgotPasswordEmailBody
            ));
            break;
          case "delete-account":
            dispatch(setDeleteAccountEmailSubject(
              Response.deleteAccountEmail
                ? Response.deleteAccountEmail.subject
                : deleteAccountEmailSubject
            ));
            dispatch(setDeleteAccountEmailBody(
              Response.deleteAccountEmail
                ? Response.deleteAccountEmail.body
                : deleteAccountEmailBody
            ));
            break;
          case "add-email":
            dispatch(setAddEmailSubject(
              Response.addEmail ? Response.addEmail.subject : addEmailSubject
            ));
            dispatch(setAddEmailBody(
              Response.addEmail ? Response.addEmail.body : addEmailBody
            ));
            break;
        }
        dispatch(setEmailGenerationLoading(false));
      })
      .catch((e) => {
        console.log(e);
        dispatch(setEmailGenerationLoading(false));
      });
  };

  // if no argument is passed, load the theme
  const iframePreviewLoad = (
    templateName?: string,
    page?: string,
    action?: string
  ): string => {
    templateName = templateName ? templateName : "001";
    let previewURL =
      "/templates/" + action + "/" + templateName + "/" + page + ".html";
    let previewState;
    switch (action) {
      case "login":
        previewState = {
          logo: selectedLogo,
          body_background_image: loginBackgroundImage,
          body_background_color: loginBackgroundColor,
          body_brand_color: loginBrandColor,
          card_color: loginCardColor,
          text_color: loginTextColor,
          font: loginFont,
          signin_title: loginTemplateContent.signinTitle,
          signin_subtitle: loginTemplateContent.signinSubtitle,
          signup_title: loginTemplateContent.signupTitle,
          signup_subtitle: loginTemplateContent.signupSubtitle,
          forgot_password_title: loginTemplateContent.forgotPasswordTitle,
          forgot_password_subtitle: loginTemplateContent.forgotPasswordSubtitle,
          mfa_title: loginTemplateContent.mfaTitle,
          mfa_subtitle: loginTemplateContent.mfaSubtitle,
          link_color: loginLinkColor,
          input_background_color: loginInputBackgroundColor,
          input_border_color: loginInputBorderColor,
          button_background_color: loginButtonBackgroundColor,
          button_text_color: loginButtonTextColor,
          social_button_layout: loginSocialButtonLayout,
          language: loginLanguage,
          translation_data: loginTranslationData,
        };
        localStorage.setItem("login", JSON.stringify(previewState));
        break;
      case "email":
        previewState = {
          logo: selectedLogo,
          logoAlignment: emailLogoAlignment,
          language: emailLanguage,
          body_background_color: emailBackgroundColor,
          body_brand_color: emailBrandColor,
          card_color: emailCardColor,
          text_color: emailTextColor,
          font: emailFont,
          link_color: emailLinkColor,
          button_background_color: emailButtonBackgroundColor,
          button_text_color: emailButtonTextColor,

          welcome_email_subject: welcomeEmailSubject,
          welcome_email_body: welcomeEmailBody,
          verification_email_subject: verificationEmailSubject,
          verification_email_body: verificationEmailBody,
          reset_password_email_subject: resetPasswordEmailSubject,
          reset_password_email_body: resetPasswordEmailBody,
          forgot_password_email_subject: forgotPasswordEmailSubject,
          forgot_password_email_body: forgotPasswordEmailBody,
          add_email_subject: addEmailSubject,
          add_email_body: addEmailBody,
          delete_account_email_subject: deleteAccountEmailSubject,
          delete_account_email_body: deleteAccountEmailBody,

          social_link_facebook: socialLinkFacebook,
          social_link_twitter: socialLinkTwitter,
          social_link_linkedin: socialLinkLinkedin,
          social_link_instagram: socialLinkInstagram,
          social_link_youtube: socialLinkYoutube,
          social_link_discord: socialLinkDiscord,

          link_privacy_policy: linkPrivacyPolicy,
          link_term_and_condition: linkTermAndCondition,
          link_unsubscribe: linkUnsubscribe,
          copyright_address: copyrightAddress,
        };
        localStorage.setItem("email", JSON.stringify(previewState));
        break;
      case "sms":
        previewState = {
          welcome_sms_body: welcomeSmsBody,
          verification_sms_body: verificationSmsBody,
          phone_number_change_sms_body: phoneNumberChangeSmsBody,
          reset_password_sms_body: resetPasswordSmsBody,
        };
        localStorage.setItem("sms", JSON.stringify(previewState));
        break;
    }
    return previewURL ? previewURL + "?t=" + Date.now() : "Loading...";
  };

  useEffect(() => {
    let selectedPalette;
    if (selectedColorPalette < colorPalettes.length) {
      if (typeof colorPalettes[selectedColorPalette] !== "undefined") {
        selectedPalette = colorPalettes[selectedColorPalette];
      }
    } else {
      if (
        typeof logoUploadColorPalettes[
        selectedColorPalette - colorPalettes.length
        ] !== "undefined"
      ) {
        selectedPalette =
          logoUploadColorPalettes[selectedColorPalette - colorPalettes.length];
      }
    }

    if (selectedPalette) {
      //login
      dispatch(setLoginBrandColor(selectedPalette.brandColor));
      dispatch(setLoginBackgroundColor(selectedPalette.bodyColor));
      dispatch(setLoginCardColor(selectedPalette.cardColor));
      dispatch(setLoginTextColor(selectedPalette.textColor));
      dispatch(setLoginLinkColor(selectedPalette.brandColor));
      dispatch(setLoginInputBackgroundColor(selectedPalette.cardColor));
      dispatch(setLoginInputBorderColor(selectedPalette.textColor));
      dispatch(setLoginButtonBackgroundColor(selectedPalette.brandColor));
      dispatch(setLoginButtonTextColor(selectedPalette.bodyColor));

      //email
      dispatch(setEmailBrandColor(selectedPalette.brandColor));
      dispatch(setEmailBackgroundColor(selectedPalette.bodyColor));
      dispatch(setEmailCardColor(selectedPalette.cardColor));
      dispatch(setEmailTextColor(selectedPalette.textColor));
      dispatch(setEmailLinkColor(selectedPalette.brandColor));
      dispatch(setEmailButtonBackgroundColor(selectedPalette.brandColor));
      dispatch(setEmailButtonTextColor(selectedPalette.bodyColor));
    }
  }, [selectedColorPalette, colorPalettes, logoUploadColorPalettes, dispatch]);

  useEffect(() => {
    if (logoUploadFile) {
      dispatch(setLogoUploadSrc(logoUploadFile));
      dispatch(setSelectedLogo(logoUploadFile));
    } else {
      dispatch(setLogoUploadSrc(""));
      dispatch(setLogoUploadColorPalettes([]));
      dispatch(setSelectedLogo(logos.length > 0 ? logos[0] : ""));
      dispatch(setSelectedColorPalette(0));
    }
  }, [logoUploadFile, logos, dispatch]);

  useEffect(() => {
    fetch("/translation/login/" + loginLanguage + ".json") // Assuming data.json is in the public folder
      .then((response) => response.json())
      .then((translation) => {
        dispatch(setLoginTranslationData(translation));
        dispatch(setLoginTemplateContent({
          signinTitle: translation.str_sign_in,
          signinSubtitle: "",
          signupTitle: translation.str_sign_up,
          signupSubtitle: "",
          forgotPasswordTitle: translation.str_forgot_password,
          forgotPasswordSubtitle: "",
          mfaTitle: translation.str_enter_code_from_sms,
          mfaSubtitle: translation.str_code_has_been_sent_to_your_phone,
        }));
      })
      .catch((error) => {
        dispatch(setLoginLanguage("en"));
      });
  }, [loginLanguage, dispatch]);

  useEffect(() => {
    fetch("/translation/sms/" + smsLanguage + ".json") // Assuming data.json is in the public folder
      .then((response) => response.json())
      .then((translation) => {
        dispatch(setWelcomeSmsBody(translation.welcome_sms_body));
        dispatch(setVerificationSmsBody(translation.verification_sms_body));
        dispatch(setPhoneNumberChangeSmsBody(translation.phone_number_change_sms_body));
        dispatch(setResetPasswordSmsBody(translation.reset_password_sms_body));
      })
      .catch((error) => {
        dispatch(setSmsLanguage("en"));
      });
  }, [smsLanguage, dispatch]);

  const processingBar = () => {
    setTimeout(function () {
      if (progress < 99) {
        setProgress(progress + 1);
      } else {
        //setProgress(0);
      }
    }, 200);
  };

  const processLogoResp = (logoResponse: logoResponse) => {
    if (logoResponse.statusCode === 200) {
      setProgress(0);
      if (logoResponse.body) {
        if (logoResponse.body.logoSrcs) {
          dispatch(setLogos(logoResponse.body.logoSrcs));
          dispatch(setSelectedLogo(logoResponse.body.logoSrcs[0]));
        }

        if (logoResponse.body.palettes) {
          dispatch(setColorPalettes(logoResponse.body.palettes));
        }
        if (logoResponse.body.language) {
          //if (languagesList[logoResponse.body.language]) {
          dispatch(setLoginLanguage(logoResponse.body.language));
          dispatch(setEmailLanguage(logoResponse.body.language));
          setSmsLanguage(logoResponse.body.language);
          //}
        }
        if (logoResponse.body.industry) {
          dispatch(setEmailIndustry(logoResponse.body.industry));
        }
        if (logoResponse.body.fonts) {
          let isFontSet = false;
          for (const font of logoResponse.body.fonts) {
            const formattedFont = font.replace(/"+/, "").replace(/\s+/, "");
            if (fontList.includes(formattedFont) && !isFontSet) {
              dispatch(setLoginFont(formattedFont));
              dispatch(setEmailFont(formattedFont));
              isFontSet = true;
            }
          }

          if (!isFontSet) {
            dispatch(setLoginFont("Barlow"));
            dispatch(setEmailFont("Barlow"));
          }
        }
        dispatch(setSocialLinkFacebook(
          logoResponse.body.socialLinks
            ? logoResponse.body.socialLinks.facebook
            : ""
        ));
        dispatch(setSocialLinkTwitter(
          logoResponse.body.socialLinks
            ? logoResponse.body.socialLinks.twitter
            : ""
        ));
        dispatch(setSocialLinkLinkedin(
          logoResponse.body.socialLinks
            ? logoResponse.body.socialLinks.linkedin
            : ""
        ));
        dispatch(setSocialLinkYoutube(
          logoResponse.body.socialLinks
            ? logoResponse.body.socialLinks.youtube
            : ""
        ));
        dispatch(setSocialLinkInstagram(
          logoResponse.body.socialLinks
            ? logoResponse.body.socialLinks.instagram
            : ""
        ));
        dispatch(setSocialLinkDiscord(
          logoResponse.body.socialLinks
            ? logoResponse.body.socialLinks.discord
            : ""
        ));
        dispatch(setLinkPrivacyPolicy(
          logoResponse.body.privacyPolicy ? logoResponse.body.privacyPolicy : ""
        ));
        dispatch(setLinkTermAndCondition(
          logoResponse.body.termsAndConditions
            ? logoResponse.body.termsAndConditions
            : ""
        ));
        dispatch(setCopyrightAddress(
          logoResponse.body.copyrightText ? logoResponse.body.copyrightText : ""
        ));
      }
    }
  };

  const onSubmitClick = () => {
    // setLogos([]);
    if (currentStep === 0) {
      dispatch(setLoadingStep());
      let encodeURL = "";
      try {
        var loc = new URL(url);
        encodeURL = loc.hostname
          ? encodeURIComponent(loc.hostname)
          : encodeURIComponent(url);
      } catch (e) {
        encodeURL = encodeURIComponent(url);
      }

      fetch(baseUrl + `/templateinfo?endpoint=${encodeURL}`, {
        mode: "cors",
        headers: { "Content-Type": "application/json" },
      })
        .then((resp) => {
          return resp.json();
        })
        .then((logoResponse) => {
          processLogoResp(logoResponse);
          dispatch(setStep(1));
        })
        .catch((e) => {
          dispatch(setStep(0));
        });
      let emailTypes = [
        "welcome",
        "verification",
        "forgot-password",
        "delete-account",
        "add-email",
        "reset-password",
      ];
      for (var i in emailTypes) {
        generateAIEmail(emailTypes[i]);
      }
    }
  };

  const renderBody = () => {
    switch (currentStep) {
      case -1:
        processingBar();
        return (
          <div className="loading-spinner">
            <div className="learnMore">
              <p>
                <strong>
                  <span>Retrieve company logo image</span>
                  <span>Capture preferred font family</span>
                  <span>Extract primary brand color</span>
                  <span>Ensure consistent brand representation</span>
                  <span>Enhance user experience with branding</span>
                </strong>
              </p>
            </div>
            <div className="progress">
              <div className="bar" style={{ width: progress + "%" }}>
                <p className="percent">{progress}%</p>
              </div>
            </div>
          </div>
        );
      case 0:
        return (
          <Step1 onSubmitClick={onSubmitClick} />
        );
      case 1:
        return (
          <Step2
            baseUrl={baseUrl}
            iframePreviewLoad={iframePreviewLoad}
          />
        );
      case 2:
        return (
          <Step3
            iframePreviewLoad={iframePreviewLoad}
          />
        );
      case 3:
        return (
          <Step5
            baseUrl={baseUrl}
            iframePreviewLoad={iframePreviewLoad}
            generateAIEmail={generateAIEmail}
          />
        );
      default:
        return (
          <div>
            <div className="page-header">
              <h1 className="lr-tab-header" id="head_LoginScreen">
                Coming Soon
              </h1>
              <div className="panel-heading">
                <p className="idx-header pb-3" id="p_LoginScreen_Description">
                  Coming Soon
                </p>
              </div>
            </div>
            <div className="page-body">
              <div>Coming Soon!</div>
            </div>
            <div className="page-footer">
              <button className="btn btn-ghost" onClick={() => dispatch(navigateBack())}>
                Previous
              </button>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      {/* Header */}
      <header>
        <div>
          <a href="/">
            <img src={logoDark} alt="LoginRadius" />
          </a>
        </div>
      </header>
      {/* Header */}

      {/* Container */}
      <main className="container">
        <section>{renderBody()}</section>
      </main>
      {/* Container */}

      {/* Footer */}
      <footer>
        <div className="container">
          <div>
            <div>
              <p>&copy;{currentYear} LoginRadius Inc.</p>
              <ul>
                <li>
                  <a
                    className="anchor-link global-link"
                    href="https://www.loginradius.com/terms"
                    id="lnk_dashboard_Terms"
                    rel="noopener noreferrer"
                    target="_blank"
                    title=""
                  >
                    Terms
                  </a>
                </li>
                <li>
                  <a
                    className="anchor-link global-link"
                    href="https://www.loginradius.com/privacy"
                    id="lnk_dashboard_Privacy"
                    rel="noopener noreferrer"
                    target="_blank"
                    title=""
                  >
                    Privacy
                  </a>
                </li>
                <li>
                  <a
                    className="anchor-link global-link"
                    href="https://status.loginradius.com"
                    id="lnk_dashboard_System_Status"
                    rel="noopener noreferrer"
                    target="_blank"
                    title=""
                  >
                    System Status
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-logo">
            <img className="brand-logo" src={logoDark} alt="LoginRadius Logo" />
          </div>
          <div>
            <p>
              Last Login Activity:
              {/* eslint-disable-next-line */}
              <a
                className="anchor-link global-link"
                id="lnk_dashboard_Last_Account_Activity"
                href="#"
              >
                30 day(s) ago
              </a>
            </p>
            <p>IP: 10.1.27.13</p>
          </div>
        </div>
      </footer>
      {/* Footer */}

      {/* <div className="lr-footer">
        {currentStep !== 0 && (
          <button
            onClick={() => {
              setStep(0);
            }}
          >
            Previous
          </button>
        )}
        <p>&copy;{currentYear} LoginRadius Inc.</p>
        {currentStep !== 0 && <button>Next</button>}
      </div> */}
    </>
  );
}

export default App;

import { createSlice } from '@reduxjs/toolkit'

export interface smsTemplateInterface {
  smsTemplates: string[],
  smsSelectedTab: number,
  smsSelectedTemplate: string,
  smsLanguage: string,
  welcomeSmsBody: string,
  verificationSmsBody: string,
  phoneNumberChangeSmsBody: string,
  resetPasswordSmsBody: string,
}

const initialStateValue: smsTemplateInterface = {
  smsTemplates: ["001"],
  smsSelectedTab: 11,
  smsSelectedTemplate: "001",
  smsLanguage: "en",
  welcomeSmsBody: "",
  verificationSmsBody: "",
  phoneNumberChangeSmsBody: "",
  resetPasswordSmsBody: "",
};

export const smsTemplateValuesSlice = createSlice({
  name: 'commonValues',
  initialState: initialStateValue,
  reducers: {
    resetSmsTemplateValues: (state) => {
      state = initialStateValue;
    },
    setSmsSelectedTab: (state, action) => {
      if (action.payload) {
        state.smsSelectedTab = action.payload;
      } else {
        state.smsSelectedTab = initialStateValue.smsSelectedTab
      }
    },
    setSmsSelectedTemplate: (state, action) => {
      if (action.payload) {
        state.smsSelectedTemplate = action.payload;
      } else {
        state.smsSelectedTemplate = initialStateValue.smsSelectedTemplate
      }
    },
    setSmsLanguage: (state, action) => {
      if (action.payload) {
        state.smsLanguage = action.payload;
      } else {
        state.smsLanguage = initialStateValue.smsLanguage
      }
    },
    setWelcomeSmsBody: (state, action) => {
      if (action.payload) {
        state.welcomeSmsBody = action.payload;
      } else {
        state.welcomeSmsBody = initialStateValue.welcomeSmsBody
      }
    },
    setVerificationSmsBody: (state, action) => {
      if (action.payload) {
        state.verificationSmsBody = action.payload;
      } else {
        state.verificationSmsBody = initialStateValue.verificationSmsBody
      }
    },
    setPhoneNumberChangeSmsBody: (state, action) => {
      if (action.payload) {
        state.phoneNumberChangeSmsBody = action.payload;
      } else {
        state.phoneNumberChangeSmsBody = initialStateValue.phoneNumberChangeSmsBody
      }
    },
    setResetPasswordSmsBody: (state, action) => {
      if (action.payload) {
        state.resetPasswordSmsBody = action.payload;
      } else {
        state.resetPasswordSmsBody = initialStateValue.resetPasswordSmsBody
      }
    },
  },
})

export const {
  resetSmsTemplateValues,
  setSmsSelectedTab,
  setSmsSelectedTemplate,
  setSmsLanguage,
  setWelcomeSmsBody,
  setVerificationSmsBody,
  setPhoneNumberChangeSmsBody,
  setResetPasswordSmsBody,
} = smsTemplateValuesSlice.actions

export default smsTemplateValuesSlice.reducer
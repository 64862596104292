import React, { useState } from "react";
import LanguageCard from "../components/languagesCard";
import Editor from "./smsRichTechEditor";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setViewPort } from "../store/commonValues";
import { setPhoneNumberChangeSmsBody, setResetPasswordSmsBody, setSmsLanguage, setVerificationSmsBody, setWelcomeSmsBody } from "../store/smsTemplateValues";

function SmsThemeCustomize(props: {
  //common
  iframePage: string;
  iframePreviewLoad(templateName: string, page: string, action: string): string;
}) {
  const {
    viewPort
  } = useAppSelector(state => state.commonValues);
  const {
    smsLanguage,
    welcomeSmsBody,
    verificationSmsBody,
    phoneNumberChangeSmsBody,
    resetPasswordSmsBody,
    smsSelectedTemplate,
  } = useAppSelector(state => state.smsTemplateValues);
  const dispatch = useAppDispatch();
  const [iframeLoader, setIframeLoader] = useState(true);
  return (
    <div className="customization-wrapper">
      <div
        className={
          `toggle-btn` +
          (viewPort === 418 ? " hide" : " ") +
          (viewPort === 1070 ? " show" : " ")
        }
      >
        <button
          className={`btn-desktopview`}
          onClick={() => {
            dispatch(setViewPort(418));
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_813_10995)">
              <path
                d="M0.75 10C0.75 15.1086 4.89137 19.25 10 19.25C15.1086 19.25 19.25 15.1086 19.25 10C19.25 4.89137 15.1086 0.75 10 0.75C4.89137 0.75 0.75 4.89137 0.75 10Z"
                fill="white"
                stroke="#008ECF"
                strokeWidth="1.5"
              />
              <path
                d="M7.41725 15.4477L6.9224 14.9528C6.86614 14.8965 6.83453 14.8201 6.83453 14.7405C6.83453 14.6609 6.86614 14.5845 6.9224 14.5282L11.4399 10L6.9224 5.47186C6.86614 5.41553 6.83453 5.33917 6.83453 5.25955C6.83453 5.17994 6.86614 5.10358 6.9224 5.04725L7.41725 4.55241C7.47357 4.49614 7.54993 4.46454 7.62955 4.46454C7.70916 4.46454 7.78552 4.49614 7.84185 4.55241L13.0772 9.78773C13.1334 9.84406 13.165 9.92042 13.165 10C13.165 10.0796 13.1334 10.156 13.0772 10.2123L7.84185 15.4477C7.78552 15.5039 7.70916 15.5355 7.62955 15.5355C7.54993 15.5355 7.47357 15.5039 7.41725 15.4477Z"
                fill="#008ECF"
              />
            </g>
            <defs>
              <clipPath id="clip0_813_10995">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="matrix(-1 0 0 1 20 0)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
      <div
        className={
          `accordion-warpper` +
          (viewPort === 1070 ? " desktop-active" : " ")
        }
      >
        <div className="sub-header">
          <h2>Customization</h2>
        </div>
        <div className="accordion-tabs">
          <div className="accordion-tab">
            <input type="radio" id={`sms${props.iframePage}`} name="rd" />
            <label
              className="accordion-tab-label"
              htmlFor={`sms${props.iframePage}`}
            >
              {"Content"}
            </label>
            <div className="accordion-tab-content">
              <div className="customization-form">
                <div className="form-control ">
                  <LanguageCard
                    label="Languages"
                    value={smsLanguage}
                    setValue={(lan) => dispatch(setSmsLanguage(lan))}
                  />
                </div>
                {props.iframePage === "welcome-sms" ? (
                  <>
                    <div className="form-control ">
                      <Editor
                        label={`Body Text`}
                        value={welcomeSmsBody}
                        setValue={(value) => {
                          dispatch(setWelcomeSmsBody(value))
                        }}
                      />
                    </div>
                  </>
                ) : props.iframePage === "phone-number-verification" ? (
                  <>
                    <div className="form-control ">
                      <Editor
                        label={`Body Text`}
                        value={verificationSmsBody}
                        setValue={(value) => {
                          dispatch(setVerificationSmsBody(value))
                        }}
                      />
                    </div>
                  </>
                ) : props.iframePage === "phone-number-change" ? (
                  <>
                    <div className="form-control ">
                      <Editor
                        label={`Body Text`}
                        value={
                          phoneNumberChangeSmsBody
                        }
                        setValue={(value) => {
                          dispatch(setPhoneNumberChangeSmsBody(value))
                        }}
                      />
                    </div>
                  </>
                ) : props.iframePage === "reset-password" ? (
                  <>
                    <div className="form-control ">
                      <Editor
                        label={`Body Text`}
                        value={resetPasswordSmsBody}
                        setValue={(value) => {
                          dispatch(setResetPasswordSmsBody(value))
                        }}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="customization-preview"
        style={{ width: `${viewPort === 1070 ? "100%" : ""}` }}
      >
        <div
          className={`toggle-btn` + (viewPort === 1070 ? "" : " mobile")}
        >
          <button
            className={
              `btn-mobileview` + (viewPort === 1070 ? " hide" : " show ")
            }
            onClick={() => {
              dispatch(setViewPort(1070));
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_813_10995)">
                <path
                  d="M0.75 10C0.75 15.1086 4.89137 19.25 10 19.25C15.1086 19.25 19.25 15.1086 19.25 10C19.25 4.89137 15.1086 0.75 10 0.75C4.89137 0.75 0.75 4.89137 0.75 10Z"
                  fill="white"
                  stroke="#008ECF"
                  strokeWidth="1.5"
                />
                <path
                  d="M12.5823 4.55241L13.0771 5.04725C13.1334 5.10358 13.165 5.17994 13.165 5.25955C13.165 5.33917 13.1334 5.41553 13.0771 5.47186L8.55965 10L13.0771 14.5282C13.1334 14.5845 13.165 14.6609 13.165 14.7405C13.165 14.8201 13.1334 14.8965 13.0771 14.9528L12.5823 15.4476C12.5259 15.5039 12.4496 15.5355 12.37 15.5355C12.2903 15.5355 12.214 15.5039 12.1577 15.4476L6.92235 10.2123C6.86608 10.156 6.83447 10.0796 6.83447 10C6.83447 9.92041 6.86608 9.84405 6.92235 9.78773L12.1577 4.55241C12.214 4.49614 12.2903 4.46454 12.37 4.46454C12.4496 4.46454 12.5259 4.49614 12.5823 4.55241Z"
                  fill="#008ECF"
                />
              </g>
              <defs>
                <clipPath id="clip0_813_10995">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="matrix(-1 0 0 1 20 0)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
        <div
          className={`iframe${viewPort === 1070 ? " desktopBrower" : " mobileBrower"}`}
        >
          <iframe
            className="cardTemplate"
            title="idx Preview"
            style={{ width: `100%`, height: `50vh` }}
            srcDoc={iframeLoader === false ? undefined : "loading..."}
            onLoad={(e) => {
              setIframeLoader(false);
            }}
            onChange={() => { setIframeLoader(true); }}
            src={props.iframePreviewLoad(
              smsSelectedTemplate,
              props.iframePage,
              "sms"
            )}
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default SmsThemeCustomize;

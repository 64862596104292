import React from "react";
import LoginThemeCustomize from "../components/loginThemeCustomize";
import EmailThemeCustomize from "../components/emailThemeCustomize";
import SmsThemeCustomize from "../components/smsThemeCustomize";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { CurrentTabEnum, navigateBack, setCurrentTab, setViewPort } from "../store/commonValues";
import { setLoginSelectedTab } from "../store/loginTemplateValues";
import { setEmailSelectedTab } from "../store/emailTemplateValues";
import { setSmsSelectedTab } from "../store/smsTemplateValues";

function Step5customization(props: {
  //common
  baseUrl: string;
  iframePreviewLoad: (
    templateName?: string,
    page?: string,
    action?: string
  ) => string;

  //email
  generateAIEmail: (value: string) => void;
}) {
  const {
    viewPort,
    currentTab,
  } = useAppSelector(state => state.commonValues);
  const {
    loginSelectedTemplate,
    loginSelectedTab
  } = useAppSelector(state => state.loginTemplateValues);
  const {
    emailSelectedTab,
    emailSelectedTemplate,
  } = useAppSelector(state => state.emailTemplateValues);
  const {
    smsSelectedTab,
  } = useAppSelector(state => state.smsTemplateValues);
  const dispatch = useAppDispatch();

  const selectTab = (tab: number) => {
    if ([1, 2, 3, 4].includes(tab)) {
      dispatch(setLoginSelectedTab(tab));
      dispatch(setCurrentTab(CurrentTabEnum.LOGIN));
    } else if ([5, 6, 7, 8, 9, 10].includes(tab)) {
      dispatch(setEmailSelectedTab(tab));
      dispatch(setCurrentTab(CurrentTabEnum.EMAIL));
    } else if ([11, 12, 13, 14].includes(tab)) {
      dispatch(setSmsSelectedTab(tab));
      dispatch(setCurrentTab(CurrentTabEnum.SMS));
    }
  };

  return (
    <>
      <div>
        <div className="page-header">
          <h1 className="lr-tab-header" id="head_LoginScreen">
            Customize your template
          </h1>
          <div className="panel-heading">
            <p className="idx-header pb-3" id="p_LoginScreen_Description">
              Please review your template and customize the appearance as
              needed.
            </p>
          </div>
        </div>
        <div className="page-body">
          <div className="tab-warpper">
            <input
              className="radio"
              id="customize_login"
              name="customize_group111"
              type="radio"
              defaultChecked={
                currentTab === CurrentTabEnum.LOGIN && [1, 2, 3, 4].indexOf(loginSelectedTab) !== -1 ? true : false
              }
              onChange={() => {
                dispatch(setCurrentTab(CurrentTabEnum.LOGIN));
              }}
            />
            <input
              className="radio"
              id="customize_email"
              name="customize_group111"
              type="radio"
              defaultChecked={
                currentTab === CurrentTabEnum.EMAIL && [5, 6, 7, 8, 9, 10].indexOf(emailSelectedTab) !== -1 ? true : false
              }
              onChange={() => {
                dispatch(setCurrentTab(CurrentTabEnum.EMAIL));
              }}
            />
            <input
              className="radio"
              id="customize_sms"
              name="customize_group111"
              type="radio"
              defaultChecked={
                currentTab === CurrentTabEnum.SMS && [11, 12, 13, 14].indexOf(smsSelectedTab) !== -1 ? true : false
              }
              onChange={() => {
                dispatch(setCurrentTab(CurrentTabEnum.SMS));
              }}
            />
            <div className="tabs">
              <div>
                <label
                  className="tab"
                  id="customize_login_one_tab"
                  htmlFor="customize_login"
                  defaultChecked={
                    currentTab === CurrentTabEnum.LOGIN && [1, 2, 3, 4].indexOf(loginSelectedTab) !== -1
                      ? false
                      : true
                  }
                  onChange={() => {
                    dispatch(setCurrentTab(CurrentTabEnum.LOGIN));
                  }}
                >
                  Login
                </label>
                <label
                  className="tab"
                  id="customize_email_two_tab"
                  htmlFor="customize_email"
                  defaultChecked={
                    currentTab === CurrentTabEnum.EMAIL && [5, 6, 7, 8, 9, 10].indexOf(emailSelectedTab) !== -1
                      ? false
                      : true
                  }
                  onChange={() => {
                    dispatch(setCurrentTab(CurrentTabEnum.EMAIL));
                  }}
                >
                  Email
                </label>
                <label
                  className="tab"
                  id="customize_sms_three_tab"
                  htmlFor="customize_sms"
                  defaultChecked={
                    currentTab === CurrentTabEnum.SMS && [11, 12, 13, 14].indexOf(smsSelectedTab) !== -1
                      ? false
                      : true
                  }
                  onChange={() => {
                    dispatch(setCurrentTab(CurrentTabEnum.SMS));
                  }}
                >
                  SMS
                </label>
              </div>
              <div className="btn-group action-buttons">
                <div>
                  {currentTab !== CurrentTabEnum.SMS ? (
                    <>
                      <button
                        className={
                          `btn-desktopview` +
                          (viewPort === 1070 ? " active" : "")
                        }
                        onClick={() => {
                          dispatch(setViewPort(1070));
                        }}
                      >
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20 3.5H4C2.89543 3.5 2 4.39543 2 5.5V15.5C2 16.6046 2.89543 17.5 4 17.5H20C21.1046 17.5 22 16.6046 22 15.5V5.5C22 4.39543 21.1046 3.5 20 3.5Z"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 21.5H16"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 17.5V21.5"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <button
                        className={
                          `btn-mobileview` +
                          (viewPort === 418 ? " active" : "")
                        }
                        onClick={() => {
                          dispatch(setViewPort(418));
                        }}
                      >
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17 2.5H7C5.89543 2.5 5 3.39543 5 4.5V20.5C5 21.6046 5.89543 22.5 7 22.5H17C18.1046 22.5 19 21.6046 19 20.5V4.5C19 3.39543 18.1046 2.5 17 2.5Z"
                            stroke="#41484E"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 18.5H12.01"
                            stroke="#41484E"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </>
                  ) : ""}
                </div>

                <div>
                  {currentTab === CurrentTabEnum.LOGIN ? (
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        switch (loginSelectedTab) {
                          case 1:
                            window.open(
                              props.iframePreviewLoad(
                                loginSelectedTemplate,
                                "login",
                                "login"
                              ),
                              "_blank"
                            );
                            break;
                          case 2:
                            window.open(
                              props.iframePreviewLoad(
                                loginSelectedTemplate,
                                "signup",
                                "login"
                              ),
                              "_blank"
                            );
                            break;
                          case 3:
                            window.open(
                              props.iframePreviewLoad(
                                loginSelectedTemplate,
                                "forgotten-password",
                                "login"
                              ),
                              "_blank"
                            );
                            break;
                          case 4:
                            window.open(
                              props.iframePreviewLoad(
                                loginSelectedTemplate,
                                "mfa",
                                "login"
                              ),
                              "_blank"
                            );
                            break;
                        }
                      }}
                    >
                      {"Try This"}
                    </button>
                  ) : (currentTab === CurrentTabEnum.EMAIL ? (
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        switch (emailSelectedTab) {
                          case 5:
                            window.open(
                              props.iframePreviewLoad(
                                emailSelectedTemplate,
                                "welcome",
                                "email"
                              ),
                              "_blank"
                            );
                            break;
                          case 6:
                            window.open(
                              props.iframePreviewLoad(
                                emailSelectedTemplate,
                                "verification",
                                "email"
                              ),
                              "_blank"
                            );
                            break;
                          case 7:
                            window.open(
                              props.iframePreviewLoad(
                                emailSelectedTemplate,
                                "forgot-password",
                                "email"
                              ),
                              "_blank"
                            );
                            break;
                          case 8:
                            window.open(
                              props.iframePreviewLoad(
                                emailSelectedTemplate,
                                "delete-account",
                                "email"
                              ),
                              "_blank"
                            );
                            break;
                          case 9:
                            window.open(
                              props.iframePreviewLoad(
                                emailSelectedTemplate,
                                "add-email",
                                "email"
                              ),
                              "_blank"
                            );
                            break;
                          case 10:
                            window.open(
                              props.iframePreviewLoad(
                                emailSelectedTemplate,
                                "reset-password",
                                "email"
                              ),
                              "_blank"
                            );
                            break;
                        }
                      }}
                    >
                      {"Try This"}
                    </button>
                  ) : "")}
                </div>
              </div>
            </div>
            <div className="panels">
              <div className="panel" id="customize_login_one_panel">
                <div className="tab-warpper">
                  <input
                    className="radio"
                    id="one"
                    name="customize_login_group"
                    type="radio"
                    checked={[1].indexOf(loginSelectedTab) ? false : true}
                    onChange={() => {
                      selectTab(1);
                    }}
                  />
                  <input
                    className="radio"
                    id="two"
                    name="customize_login_group"
                    type="radio"
                    checked={[2].indexOf(loginSelectedTab) ? false : true}
                    onChange={() => {
                      selectTab(2);
                    }}
                  />
                  <input
                    className="radio"
                    id="three"
                    name="customize_login_group"
                    type="radio"
                    checked={[3].indexOf(loginSelectedTab) ? false : true}
                    onChange={() => {
                      selectTab(3);
                    }}
                  />
                  <input
                    className="radio"
                    id="four"
                    name="customize_login_group"
                    type="radio"
                    checked={[4].indexOf(loginSelectedTab) ? false : true}
                    onChange={() => {
                      selectTab(4);
                    }}
                  />
                  <div className="tabs">
                    <div>
                      <label className="tab" id="one-tab" htmlFor="one">
                        Sign in
                      </label>
                      <label className="tab" id="two-tab" htmlFor="two">
                        Sign up
                      </label>
                      <label className="tab" id="three-tab" htmlFor="three">
                        Forgot password
                      </label>
                      <label className="tab" id="four-tab" htmlFor="four">
                        MFA
                      </label>
                    </div>
                  </div>

                  <div className="panels">
                    <div className="panel" id="one-panel">
                      <LoginThemeCustomize
                        iframePreviewLoad={props.iframePreviewLoad}
                        iframePage={"login"}
                      />
                    </div>
                    <div className="panel" id="two-panel">
                      <LoginThemeCustomize
                        iframePreviewLoad={props.iframePreviewLoad}
                        iframePage={"signup"}
                      />
                    </div>
                    <div className="panel" id="three-panel">
                      <LoginThemeCustomize
                        iframePreviewLoad={props.iframePreviewLoad}
                        iframePage={"forgotten-password"}
                      />
                    </div>
                    <div className="panel" id="four-panel">
                      <LoginThemeCustomize
                        iframePreviewLoad={props.iframePreviewLoad}
                        iframePage={"mfa"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel" id="customize_email_two_panel">
                <div className="tab-warpper">
                  <input
                    className="radio"
                    id="five"
                    name="customize_email_group"
                    type="radio"
                    checked={[5].indexOf(emailSelectedTab) ? false : true}
                    onChange={() => {
                      selectTab(5);
                    }}
                  />
                  <input
                    className="radio"
                    id="six"
                    name="customize_email_group"
                    type="radio"
                    checked={[6].indexOf(emailSelectedTab) ? false : true}
                    onChange={() => {
                      selectTab(6);
                    }}
                  />
                  <input
                    className="radio"
                    id="seven"
                    name="customize_email_group"
                    type="radio"
                    checked={[7].indexOf(emailSelectedTab) ? false : true}
                    onChange={() => {
                      selectTab(7);
                    }}
                  />
                  <input
                    className="radio"
                    id="eight"
                    name="customize_email_group"
                    type="radio"
                    checked={[8].indexOf(emailSelectedTab) ? false : true}
                    onChange={() => {
                      selectTab(8);
                    }}
                  />
                  <input
                    className="radio"
                    id="nine"
                    name="customize_email_group"
                    type="radio"
                    checked={[9].indexOf(emailSelectedTab) ? false : true}
                    onChange={() => {
                      selectTab(9);
                    }}
                  />
                  <input
                    className="radio"
                    id="ten"
                    name="customize_email_group"
                    type="radio"
                    checked={[10].indexOf(emailSelectedTab) ? false : true}
                    onChange={() => {
                      selectTab(10);
                    }}
                  />
                  <div className="tabs">
                    <div>
                      <label className="tab" id="five-tab" htmlFor="five">
                        Welcome Email
                      </label>
                      <label className="tab" id="six-tab" htmlFor="six">
                        Verification Email
                      </label>
                      <label className="tab" id="seven-tab" htmlFor="seven">
                        Forgot Password Email
                      </label>
                      <label className="tab" id="eight-tab" htmlFor="eight">
                        Delete Account Email
                      </label>
                      <label className="tab" id="nine-tab" htmlFor="nine">
                        Add Email
                      </label>
                      <label className="tab" id="ten-tab" htmlFor="ten">
                        Password Reset Email
                      </label>
                    </div>
                  </div>

                  <div className="panels">
                    <div className="panel" id="five-panel">
                      <EmailThemeCustomize
                        iframePreviewLoad={props.iframePreviewLoad}
                        generateAIEmail={props.generateAIEmail}
                        iframePage={"welcome"}
                      />
                    </div>
                    <div className="panel" id="six-panel">
                      <EmailThemeCustomize
                        iframePreviewLoad={props.iframePreviewLoad}
                        generateAIEmail={props.generateAIEmail}
                        iframePage={"verification"}
                      />
                    </div>
                    <div className="panel" id="seven-panel">
                      <EmailThemeCustomize
                        iframePreviewLoad={props.iframePreviewLoad}
                        generateAIEmail={props.generateAIEmail}
                        iframePage={"forgot-password"}
                      />
                    </div>
                    <div className="panel" id="eight-panel">
                      <EmailThemeCustomize
                        iframePreviewLoad={props.iframePreviewLoad}
                        generateAIEmail={props.generateAIEmail}
                        iframePage={"delete-account"}
                      />
                    </div>
                    <div className="panel" id="nine-panel">
                      <EmailThemeCustomize
                        iframePreviewLoad={props.iframePreviewLoad}
                        generateAIEmail={props.generateAIEmail}
                        iframePage={"add-email"}
                      />
                    </div>
                    <div className="panel" id="ten-panel">
                      <EmailThemeCustomize
                        iframePreviewLoad={props.iframePreviewLoad}
                        generateAIEmail={props.generateAIEmail}
                        iframePage={"reset-password"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel" id="customize_sms_three_panel">
                <div className="tab-warpper">
                  <input
                    className="radio"
                    id="eleven"
                    name="customize_sms_group"
                    type="radio"
                    checked={[11].indexOf(smsSelectedTab) ? false : true}
                    onChange={() => {
                      selectTab(11);
                    }}
                  />
                  <input
                    className="radio"
                    id="twelve"
                    name="customize_sms_group"
                    type="radio"
                    checked={[12].indexOf(smsSelectedTab) ? false : true}
                    onChange={() => {
                      selectTab(12);
                    }}
                  />
                  <input
                    className="radio"
                    id="thirteen"
                    name="customize_sms_group"
                    type="radio"
                    checked={[13].indexOf(smsSelectedTab) ? false : true}
                    onChange={() => {
                      selectTab(13);
                    }}
                  />
                  <input
                    className="radio"
                    id="fourteen"
                    name="customize_sms_group"
                    type="radio"
                    checked={[14].indexOf(smsSelectedTab) ? false : true}
                    onChange={() => {
                      selectTab(14);
                    }}
                  />
                  <div className="tabs">
                    <div>
                      <label className="tab" id="eleven-tab" htmlFor="eleven">
                        Welcome SMS
                      </label>
                      <label className="tab" id="twelve-tab" htmlFor="twelve">
                        Phone Number Verification
                      </label>
                      <label className="tab" id="thirteen-tab" htmlFor="thirteen">
                        Phone Number Change
                      </label>
                      <label className="tab" id="fourteen-tab" htmlFor="fourteen">
                        Password Reset
                      </label>
                    </div>
                  </div>

                  <div className="panels">
                    <div className="panel" id="eleven-panel">
                      <SmsThemeCustomize
                        iframePreviewLoad={props.iframePreviewLoad}
                        iframePage={"welcome-sms"}
                      />
                    </div>
                    <div className="panel" id="twelve-panel">
                      <SmsThemeCustomize
                        iframePreviewLoad={props.iframePreviewLoad}
                        iframePage={"phone-number-verification"}
                      />
                    </div>
                    <div className="panel" id="thirteen-panel">
                      <SmsThemeCustomize
                        iframePreviewLoad={props.iframePreviewLoad}
                        iframePage={"phone-number-change"}
                      />
                    </div>
                    <div className="panel" id="fourteen-panel">
                      <SmsThemeCustomize
                        iframePreviewLoad={props.iframePreviewLoad}
                        iframePage={"reset-password"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-footer">
          <button
            className="btn btn-ghost"
            onClick={() => {
              dispatch(navigateBack());
            }}
          >
            Back to themes
          </button>
          {currentTab === CurrentTabEnum.LOGIN ? (
            <button
              className="btn btn-primary"
              onClick={() => {
                switch (loginSelectedTab) {
                  case 1:
                    window.open(
                      props.iframePreviewLoad(
                        loginSelectedTemplate,
                        "login",
                        "login"
                      ),
                      "_blank"
                    );
                    break;
                  case 2:
                    window.open(
                      props.iframePreviewLoad(
                        loginSelectedTemplate,
                        "signup",
                        "login"
                      ),
                      "_blank"
                    );
                    break;
                  case 3:
                    window.open(
                      props.iframePreviewLoad(
                        loginSelectedTemplate,
                        "forgotten-password",
                        "login"
                      ),
                      "_blank"
                    );
                    break;
                  case 4:
                    window.open(
                      props.iframePreviewLoad(
                        loginSelectedTemplate,
                        "mfa",
                        "login"
                      ),
                      "_blank"
                    );
                    break;
                }
              }}
            >
              {"Try This"}
            </button>
          ) : (currentTab === CurrentTabEnum.EMAIL ? (
            <button
              className="btn btn-primary"
              onClick={() => {
                switch (emailSelectedTab) {
                  case 5:
                    window.open(
                      props.iframePreviewLoad(
                        emailSelectedTemplate,
                        "welcome",
                        "email"
                      ),
                      "_blank"
                    );
                    break;
                  case 6:
                    window.open(
                      props.iframePreviewLoad(
                        emailSelectedTemplate,
                        "verification",
                        "email"
                      ),
                      "_blank"
                    );
                    break;
                  case 7:
                    window.open(
                      props.iframePreviewLoad(
                        emailSelectedTemplate,
                        "forgot-password",
                        "email"
                      ),
                      "_blank"
                    );
                    break;
                  case 8:
                    window.open(
                      props.iframePreviewLoad(
                        emailSelectedTemplate,
                        "delete-account",
                        "email"
                      ),
                      "_blank"
                    );
                    break;
                  case 9:
                    window.open(
                      props.iframePreviewLoad(
                        emailSelectedTemplate,
                        "add-email",
                        "email"
                      ),
                      "_blank"
                    );
                    break;
                  case 10:
                    window.open(
                      props.iframePreviewLoad(
                        emailSelectedTemplate,
                        "reset-password",
                        "email"
                      ),
                      "_blank"
                    );
                    break;
                }
              }}
            >
              {"Try This"}
            </button>
          ) : "")}
        </div>
      </div>
    </>
  );
}

export default Step5customization;

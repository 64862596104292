import React from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function RichTechEditor(props: {
  label: string;
  value: string;
  setValue: (value: string) => void;
}) {
  let toolbarItem = ClassicEditor.defaultConfig.toolbar.items.map((item)=>{
    if(item !== "uploadImage"){
      return item;
    }
    return "";
  })
  ClassicEditor.defaultConfig.toolbar.items = toolbarItem;
  return (
    <div className="form-control editor">
      <span>{props.label}</span>
      <CKEditor
        editor={ClassicEditor}
        data={props.value}
        onReady={(editor) => {
          //console.log('CKEditor React Component is ready to use!', editor);
        }}
        onChange={(event, editor) => {
          props.setValue(editor.getData())
        }}
      />
    </div>
  );
}

export default RichTechEditor;

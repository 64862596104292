import { useState } from "react";

function SelectablePreviewCard(props: {
  ind: number;
  previewSrc: string;
  templates: string[];
  tab: string;
  isSelected: boolean;
  viewPort: number;
  setViewPort: (port: number) => void;
  onClick: (ind: number) => void;
}) {
  const [iframeLoader, setIframeLoader] = useState(true);
  return (
    <div
      className={`card light-gray${props.isSelected ? " selected" : ""}`}
      onClick={() => props.onClick(props.ind)}
    >
      <div className="cardSelected"></div>
      <h3>Template {props.templates[props.ind]}</h3>
      <div
        className={`iframe${
          props.viewPort === 1070 ? " desktopBrower" : " mobileBrower"
        }`}
      >
        <iframe
          id={`idx-iframe${props.ind}`}
          className="cardTemplate"
          title="idx Preview"
          srcDoc={iframeLoader===false?undefined:"loading..."} 
          onLoad={(e)=>{
            setIframeLoader(false);
          }}
          onChange={()=>{setIframeLoader(true);}}
          style={{
            width: `100%`,
            height: props.tab==="sms"?`50vh`:`100vh`,
          }}
          src={props.previewSrc}
        ></iframe>
      </div>
    </div>
  );
}

export default SelectablePreviewCard;

import React from "react";
import Languages from "../assets/lanaguagesList";

function LanguageCard(props: {
  label: string;
  value: string;
  setValue: (value: string) => void;
}) {
  return (
    <div className="form-control">
      <span>{props.label}</span>
      <select
        name="language"
        id="language"
        value={props.value}
        onChange={(e) => {
          props.setValue(e.target.value);
        }}
      >
        {Languages.map((lang) => (
          <option key={lang.code} value={lang.code}>{lang.lang}</option>
        ))}

      </select>
    </div>
  );
}

export default LanguageCard;
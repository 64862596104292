function LogoCard(props: {
  src: string;
  ind: number;
  isSelected: boolean;
  onClick: (ind: number) => void;
  isUploadCard?: boolean;
  clearUpload?: () => void;
  processFileUpload?: (logoFiles: FileList | null) => void;
}) {
  return props.isUploadCard ? (
    props.src ? (
      <div
        className={`card card-upload${props.isSelected ? " selected" : ""}`}
        onClick={() => props.onClick(props.ind)}
      >
        <img src={props.src} alt="" />
        {/* eslint-disable-next-line */}
        <a onClick={props.clearUpload} href="#">Remove</a>
      </div>
    ) : (
      <div className="card">
        <div className="upload-btn-wrapper">
          <button className="btn btn-primary">Choose File</button>
          <input type="file" name="myfile" onChange={(e) => props.processFileUpload && props.processFileUpload(e.target.files)} />
        </div>
      </div>
    )
  ) : (
    <div
      className={`card${props.isSelected ? " selected" : ""}`}
      onClick={() => props.onClick(props.ind)}
    >
      <img src={props.src} alt=""/>
    </div>
  );
}

export default LogoCard;

import React from "react";
import Fonts from "../assets/fontList";

function FontFamilyCard(props: {
  label: string;
  font: string;
  setFont: (value: string) => void;
}) {
  return (
    <div className="form-control">
      <span>{props.label}</span>
      <select
        name="fonts"
        id="fonts"
        //value={props.font}
        value={props.font}
        onChange={(e) => {
          props.setFont(e.target.value);
          //console.log("setting font", e.target.value);
        }}
      >
        {Fonts.map((fontFamily, ind) => (
          <option key={fontFamily} value={fontFamily}>{fontFamily}</option>
        ))}

      </select>
    </div>
  );
}

export default FontFamilyCard;
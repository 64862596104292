const languageList = [
  // {'code': 'aa', 'lang': 'Afar'},
  // {'code': 'ab', 'lang': 'Abkhazian'},
  // {'code': 'ae', 'lang': 'Avestan'},
  // {'code': 'af', 'lang': 'Afrikaans'},
  // {'code': 'ak', 'lang': 'Akan'},
  // {'code': 'am', 'lang': 'Amharic'},
  // {'code': 'an', 'lang': 'Aragonese'},
  // {'code': 'ar', 'lang': 'Arabic'},
  // {'code': 'as', 'lang': 'Assamese'},
  // {'code': 'av', 'lang': 'Avaric'},
  // {'code': 'ay', 'lang': 'Aymara'},
  // {'code': 'az', 'lang': 'Azerbaijani'},
  // {'code': 'ba', 'lang': 'Bashkir'},
  // {'code': 'be', 'lang': 'Belarusian'},
  // {'code': 'bg', 'lang': 'Bulgarian'},
  // {'code': 'bh', 'lang': 'Bihari languages'},
  // {'code': 'bi', 'lang': 'Bislama'},
  // {'code': 'bm', 'lang': 'Bambara'},
  // {'code': 'bn', 'lang': 'Bengali'},
  // {'code': 'bo', 'lang': 'Tibetan'},
  // {'code': 'br', 'lang': 'Breton'},
  // {'code': 'bs', 'lang': 'Bosnian'},
  // {'code': 'ca', 'lang': 'Catalan; Valencian'},
  // {'code': 'ce', 'lang': 'Chechen'},
  // {'code': 'ch', 'lang': 'Chamorro'},
  // {'code': 'co', 'lang': 'Corsican'},
  // {'code': 'cr', 'lang': 'Cree'},
  // {'code': 'cs', 'lang': 'Czech'},
  // {'code': 'cu', 'lang': 'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic'},
  // {'code': 'cv', 'lang': 'Chuvash'},
  // {'code': 'cy', 'lang': 'Welsh'},
  // {'code': 'da', 'lang': 'Danish'},
  { code: "zh", lang: "Chinese" },
  { code: "en", lang: "English" },
  { code: "fr", lang: "French" },
  { code: "de", lang: "German" },
  // {'code': 'dv', 'lang': 'Divehi; Dhivehi; Maldivian'},
  // {'code': 'dz', 'lang': 'Dzongkha'},
  // {'code': 'ee', 'lang': 'Ewe'},
  // {'code': 'el', 'lang': 'Greek, Modern (1453-)'},
  // {'code': 'eo', 'lang': 'Esperanto'},
  { code: "es", lang: "Spanish" },
  // {'code': 'et', 'lang': 'Estonian'},
  // {'code': 'eu', 'lang': 'Basque'},
  // {'code': 'fa', 'lang': 'Persian'},
  // {'code': 'ff', 'lang': 'Fulah'},
  // {'code': 'fi', 'lang': 'Finnish'},
  // {'code': 'fj', 'lang': 'Fijian'},
  // {'code': 'fo', 'lang': 'Faroese'},
  // {'code': 'fy', 'lang': 'Western Frisian'},
  // {'code': 'ga', 'lang': 'Irish'},
  // {'code': 'gd', 'lang': 'Gaelic; Scottish Gaelic'},
  // {'code': 'gl', 'lang': 'Galician'},
  // {'code': 'gn', 'lang': 'Guarani'},
  // {'code': 'gu', 'lang': 'Gujarati'},
  // {'code': 'gv', 'lang': 'Manx'},
  // {'code': 'ha', 'lang': 'Hausa'},
  // {'code': 'he', 'lang': 'Hebrew'},
  // {'code': 'hi', 'lang': 'Hindi'},
  // {'code': 'ho', 'lang': 'Hiri Motu'},
  // {'code': 'hr', 'lang': 'Croatian'},
  // {'code': 'ht', 'lang': 'Haitian; Haitian Creole'},
  // {'code': 'hu', 'lang': 'Hungarian'},
  // {'code': 'hy', 'lang': 'Armenian'},
  // {'code': 'hz', 'lang': 'Herero'},
  // {'code': 'ia', 'lang': 'Interlingua (International Auxiliary Language Association)'},
  // {'code': 'id', 'lang': 'Indonesian'},
  // {'code': 'ie', 'lang': 'Interlingue; Occidental'},
  // {'code': 'ig', 'lang': 'Igbo'},
  // {'code': 'ii', 'lang': 'Sichuan Yi; Nuosu'},
  // {'code': 'ik', 'lang': 'Inupiaq'},
  // {'code': 'io', 'lang': 'Ido'},
  // {'code': 'is', 'lang': 'Icelandic'},
  // {'code': 'it', 'lang': 'Italian'},
  // {'code': 'iu', 'lang': 'Inuktitut'},
  // {'code': 'ja', 'lang': 'Japanese'},
  // {'code': 'jv', 'lang': 'Javanese'},
  // {'code': 'ka', 'lang': 'Georgian'},
  // {'code': 'kg', 'lang': 'Kongo'},
  // {'code': 'ki', 'lang': 'Kikuyu; Gikuyu'},
  // {'code': 'kj', 'lang': 'Kuanyama; Kwanyama'},
  // {'code': 'kk', 'lang': 'Kazakh'},
  // {'code': 'kl', 'lang': 'Kalaallisut; Greenlandic'},
  // {'code': 'km', 'lang': 'Central Khmer'},
  // {'code': 'kn', 'lang': 'Kannada'},
  // {'code': 'ko', 'lang': 'Korean'},
  // {'code': 'kr', 'lang': 'Kanuri'},
  // {'code': 'ks', 'lang': 'Kashmiri'},
  // {'code': 'ku', 'lang': 'Kurdish'},
  // {'code': 'kv', 'lang': 'Komi'},
  // {'code': 'kw', 'lang': 'Cornish'},
  // {'code': 'ky', 'lang': 'Kirghiz; Kyrgyz'},
  // {'code': 'la', 'lang': 'Latin'},
  // {'code': 'lb', 'lang': 'Luxembourgish; Letzeburgesch'},
  // {'code': 'lg', 'lang': 'Ganda'},
  // {'code': 'li', 'lang': 'Limburgan; Limburger; Limburgish'},
  // {'code': 'ln', 'lang': 'Lingala'},
  // {'code': 'lo', 'lang': 'Lao'},
  // {'code': 'lt', 'lang': 'Lithuanian'},
  // {'code': 'lu', 'lang': 'Luba-Katanga'},
  // {'code': 'lv', 'lang': 'Latvian'},
  // {'code': 'mg', 'lang': 'Malagasy'},
  // {'code': 'mh', 'lang': 'Marshallese'},
  // {'code': 'mi', 'lang': 'Maori'},
  // {'code': 'mk', 'lang': 'Macedonian'},
  // {'code': 'ml', 'lang': 'Malayalam'},
  // {'code': 'mn', 'lang': 'Mongolian'},
  // {'code': 'mr', 'lang': 'Marathi'},
  // {'code': 'ms', 'lang': 'Malay'},
  // {'code': 'mt', 'lang': 'Maltese'},
  // {'code': 'my', 'lang': 'Burmese'},
  // {'code': 'na', 'lang': 'Nauru'},
  // {'code': 'nb', 'lang': 'Bokmål, Norwegian; Norwegian Bokmål'},
  // {'code': 'nd', 'lang': 'Ndebele, North; North Ndebele'},
  // {'code': 'ne', 'lang': 'Nepali'},
  // {'code': 'ng', 'lang': 'Ndonga'},
  // {'code': 'nl', 'lang': 'Dutch; Flemish'},
  // {'code': 'nn', 'lang': 'Norwegian Nynorsk; Nynorsk, Norwegian'},
  // {'code': 'no', 'lang': 'Norwegian'},
  // {'code': 'nr', 'lang': 'Ndebele, South; South Ndebele'},
  // {'code': 'nv', 'lang': 'Navajo; Navaho'},
  // {'code': 'ny', 'lang': 'Chichewa; Chewa; Nyanja'},
  // {'code': 'oc', 'lang': 'Occitan (post 1500)'},
  // {'code': 'oj', 'lang': 'Ojibwa'},
  // {'code': 'om', 'lang': 'Oromo'},
  // {'code': 'or', 'lang': 'Oriya'},
  // {'code': 'os', 'lang': 'Ossetian; Ossetic'},
  // {'code': 'pa', 'lang': 'Panjabi; Punjabi'},
  // {'code': 'pi', 'lang': 'Pali'},
  // {'code': 'pl', 'lang': 'Polish'},
  // {'code': 'ps', 'lang': 'Pushto; Pashto'},
  // {'code': 'pt', 'lang': 'Portuguese'},
  // {'code': 'qu', 'lang': 'Quechua'},
  // {'code': 'rm', 'lang': 'Romansh'},
  // {'code': 'rn', 'lang': 'Rundi'},
  // {'code': 'ro', 'lang': 'Romanian; Moldavian; Moldovan'},
  // {'code': 'ru', 'lang': 'Russian'},
  // {'code': 'rw', 'lang': 'Kinyarwanda'},
  // {'code': 'sa', 'lang': 'Sanskrit'},
  // {'code': 'sc', 'lang': 'Sardinian'},
  // {'code': 'sd', 'lang': 'Sindhi'},
  // {'code': 'se', 'lang': 'Northern Sami'},
  // {'code': 'sg', 'lang': 'Sango'},
  // {'code': 'si', 'lang': 'Sinhala; Sinhalese'},
  // {'code': 'sk', 'lang': 'Slovak'},
  // {'code': 'sl', 'lang': 'Slovenian'},
  // {'code': 'sm', 'lang': 'Samoan'},
  // {'code': 'sn', 'lang': 'Shona'},
  // {'code': 'so', 'lang': 'Somali'},
  // {'code': 'sq', 'lang': 'Albanian'},
  // {'code': 'sr', 'lang': 'Serbian'},
  // {'code': 'ss', 'lang': 'Swati'},
  // {'code': 'st', 'lang': 'Sotho, Southern'},
  // {'code': 'su', 'lang': 'Sundanese'},
  // {'code': 'sv', 'lang': 'Swedish'},
  // {'code': 'sw', 'lang': 'Swahili'},
  // {'code': 'ta', 'lang': 'Tamil'},
  // {'code': 'te', 'lang': 'Telugu'},
  // {'code': 'tg', 'lang': 'Tajik'},
  // {'code': 'th', 'lang': 'Thai'},
  // {'code': 'ti', 'lang': 'Tigrinya'},
  // {'code': 'tk', 'lang': 'Turkmen'},
  // {'code': 'tl', 'lang': 'Tagalog'},
  // {'code': 'tn', 'lang': 'Tswana'},
  // {'code': 'to', 'lang': 'Tonga (Tonga Islands)'},
  // {'code': 'tr', 'lang': 'Turkish'},
  // {'code': 'ts', 'lang': 'Tsonga'},
  // {'code': 'tt', 'lang': 'Tatar'},
  // {'code': 'tw', 'lang': 'Twi'},
  // {'code': 'ty', 'lang': 'Tahitian'},
  // {'code': 'ug', 'lang': 'Uighur; Uyghur'},
  // {'code': 'uk', 'lang': 'Ukrainian'},
  // {'code': 'ur', 'lang': 'Urdu'},
  // {'code': 'uz', 'lang': 'Uzbek'},
  // {'code': 've', 'lang': 'Venda'},
  // { code: "vi", lang: "Vietnamese" },
  // {'code': 'vo', 'lang': 'Volapük'},
  // {'code': 'wa', 'lang': 'Walloon'},
  // {'code': 'wo', 'lang': 'Wolof'},
  // {'code': 'xh', 'lang': 'Xhosa'},
  // {'code': 'yi', 'lang': 'Yiddish'},
  // {'code': 'yo', 'lang': 'Yoruba'},
  // {'code': 'za', 'lang': 'Zhuang; Chuang'},
  // {'code': 'zu', 'lang': 'Zulu'}
];

export default languageList;

import React from "react";

function SelectCard(props: {
  label: string;
  options: any;
  value: string;
  setValue: (value: string) => void;
}) {
  return (
    <div className="form-control">
      <span>{props.label}</span>
      <select value={props.value} onChange={(e) => {
          props.setValue(e.target.value);
        }}>
        {props.options.map((option:any, ind:any)=>{
          return (<option key={ind} value={option.value}>{option.label}</option>)
        })}        
      </select>
    </div>
  );
}

export default SelectCard;

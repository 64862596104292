import LogoCard from "../components/logoCard";
import FontFamilyCard from "../components/fontFamilyCard";
import ColorThemeCard from "../components/colorThemeCard";
import LanguageCard from "../components/languagesCard";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  navigateBack,
  navigateForward,
  resetCommonValues,
  setLogoUploadColorPalettes,
  setLogoUploadFile,
  setSelectedColorPalette,
  setSelectedLogo
} from "../store/commonValues";
import {
  resetLoginTemplateValues,
  setLoginFont,
  setLoginLanguage
} from "../store/loginTemplateValues";
import { resetEmailTemplateValues, setEmailFont, setEmailLanguage } from "../store/emailTemplateValues";
import { resetSmsTemplateValues } from "../store/smsTemplateValues";
// import logoLight from "../images/logo-light.svg";

function Step2(props: {
  baseUrl: string;
  iframePreviewLoad: (templateName?: string, page?: string, action?: string) => string;
}) {
  const {
    logos,
    selectedLogo,
    logoUploadSrc,
    colorPalettes,
    selectedColorPalette,
    logoUploadColorPalettes,
  } = useAppSelector(state => state.commonValues)
  const {
    loginLanguage,
    loginFont,
  } = useAppSelector(state => state.loginTemplateValues)
  const dispatch = useAppDispatch();

  // when clicking back from step 2, reset all states to get ready for processing a new url
  const resetAllStates = () => {
    dispatch(resetCommonValues());
    dispatch(resetLoginTemplateValues());
    dispatch(resetEmailTemplateValues());
    dispatch(resetSmsTemplateValues());
  };

  const [iframeLoader, setIframeLoader] = useState("Loading...");
  const processFileUpload = (fileList: FileList | null) => {
    if (fileList) {
      //props.setStep(-1);
      dispatch(setSelectedLogo("/loading.svg"));
      dispatch(setLogoUploadFile("/loading.svg"));
      var formdata = new FormData();
      formdata.append("logo", fileList[0], fileList[0].name);
      fetch(props.baseUrl + "/logo", {
        method: "POST",
        body: formdata,
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((result) => {
          dispatch(setLogoUploadFile(result.url));
          dispatch(setLogoUploadColorPalettes(result.palettes));
        })
        .catch((error) => console.log("error", error));
    }
  };

  return (
    <div id="step_2">
      <div className="page-header">
        <h1 className="lr-tab-header" id="head_LoginScreen">
          Customize your authentication page
        </h1>
        <div className="panel-heading">
          <p className="idx-header pb-3" id="p_LoginScreen_Description">
            We designed this UI using your website’s elements. You can modify
            the design elements as needed.
          </p>
        </div>
      </div>
      <div className="page-body">
        <div className="login-box-wrap">
          <div className="login-box">
            <div className="login-box-logo">
              <div className="sub-header">
                <h2>Logo</h2>
                <p>If we didn't pick the right logo, please upload the logo.</p>
              </div>
              <div className="logo-blocks">
                {logos.map((logo, ind) => (
                  <LogoCard
                    key={`logo-${ind}`}
                    src={logo}
                    ind={ind}
                    isSelected={logo === selectedLogo}
                    onClick={(ind: number) => {
                      dispatch(setSelectedLogo(logo));
                    }}
                  />
                ))}
                <LogoCard
                  src={logoUploadSrc}
                  ind={logos.length}
                  isSelected={logoUploadSrc === selectedLogo}
                  onClick={(ind: number) => {
                    dispatch(setSelectedLogo(logoUploadSrc));
                  }}
                  isUploadCard
                  clearUpload={() => dispatch(setLogoUploadFile(null))}
                  processFileUpload={processFileUpload}
                />
              </div>
            </div>
            <div className="login-box-font">
              <div className="sub-header">
                <h2>Languages</h2>
                <LanguageCard
                  label=""
                  value={loginLanguage}
                  setValue={(value) => {
                    dispatch(setLoginLanguage(value));
                    dispatch(setEmailLanguage(value));
                  }}
                />
              </div>
            </div>
            <div className="login-box-font">
              <div className="sub-header">
                <h2>Font Family</h2>
                <FontFamilyCard
                  label=""
                  font={loginFont}
                  setFont={(value) => {
                    dispatch(setLoginFont(value));
                    dispatch(setEmailFont(value));
                  }}
                />
              </div>
            </div>
            <div className="login-box-colors">
              <div className="sub-header">
                <h2>Page Theme</h2>
                <div className="form-control">
                  {colorPalettes.map((colors, ind) => (
                    <ColorThemeCard
                      key={ind}
                      colors={[
                        colors.brandColor,
                        colors.bodyColor,
                        colors.cardColor,
                        colors.textColor,
                      ]}
                      isSelected={ind === selectedColorPalette}
                      onClick={() => dispatch(setSelectedColorPalette(ind))}
                    />
                  ))}
                  {logoUploadColorPalettes.map((colors, ind) => (
                    <ColorThemeCard
                      key={ind}
                      colors={[
                        colors.brandColor,
                        colors.bodyColor,
                        colors.cardColor,
                        colors.textColor,
                      ]}
                      isSelected={
                        ind + colorPalettes.length ===
                        selectedColorPalette
                      }
                      onClick={() =>
                        dispatch(setSelectedColorPalette(
                          ind + colorPalettes.length
                        ))
                      }
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="iframe mobileBrower">
            <iframe
              id="idx-iframe"
              title="idx Preview"
              srcDoc={iframeLoader === "undefined" ? undefined : "loading..."}
              onLoad={(e) => {
                setIframeLoader("undefined");
              }}
              src={props.iframePreviewLoad("001", "login", "login")}
            ></iframe>
          </div>
        </div>
      </div>
      <div className="page-footer">
        <button
          className="btn btn-ghost"
          onClick={() => {
            resetAllStates();
            dispatch(navigateBack());
          }}
        >
          Previous
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            dispatch(navigateForward());
          }}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default Step2;

import { createSlice } from '@reduxjs/toolkit'

interface loginTemplateContentInterface {
  signinTitle: string,
  signinSubtitle: string,
  signupTitle: string,
  signupSubtitle: string,
  forgotPasswordTitle: string,
  forgotPasswordSubtitle: string,
  mfaTitle: string,
  mfaSubtitle: string,
}

export enum LoginSocialButtonLayoutEnum {
  TOP = "top",
  BOTTOM = "bottom"
}

export interface loginTemplateInterface {
  loginTemplates: string[],
  loginFont: string,
  loginSelectedTemplate: string,
  loginSelectedTab: number,
  loginBrandColor: string,
  loginTextColor: string,
  loginBackgroundColor: string,
  loginCardColor: string,
  loginLinkColor: string,
  loginInputBackgroundColor: string,
  loginInputBorderColor: string,
  loginButtonBackgroundColor: string,
  loginButtonTextColor: string,
  loginSocialButtonLayout: LoginSocialButtonLayoutEnum,
  loginLanguage: string,
  loginTranslationData: any,
  loginBackgroundImage: string,
  loginTemplateContent: loginTemplateContentInterface,
}

const initialStateValue: loginTemplateInterface = {
  loginTemplates: ["001", "002","003", "004", "005", "006", "007", "008"],
  loginFont: "Barlow",
  loginSelectedTemplate: "001",
  loginSelectedTab: 1,
  loginBrandColor: "#048ccc",
  loginTextColor: "#048ccc",
  loginBackgroundColor: "#0454ac",
  loginCardColor: "#0454ac",
  loginLinkColor: "#000000",
  loginInputBackgroundColor: "#000000",
  loginInputBorderColor: "#000000",
  loginButtonBackgroundColor: "#000000",
  loginButtonTextColor: "#000000",
  loginSocialButtonLayout: LoginSocialButtonLayoutEnum.TOP,
  loginLanguage: "en",
  loginTranslationData: {},
  loginBackgroundImage: "",
  loginTemplateContent: {
    signinTitle: "",
    signinSubtitle: "",
    signupTitle: "",
    signupSubtitle: "",
    forgotPasswordTitle: "",
    forgotPasswordSubtitle: "",
    mfaTitle: "",
    mfaSubtitle: "",
  },
};

export const loginTemplateValuesSlice = createSlice({
  name: 'commonValues',
  initialState: initialStateValue,
  reducers: {
    resetLoginTemplateValues: (state) => {
      state = initialStateValue;
    },
    setLoginFont: (state, action) => {
      if (action.payload) {
        state.loginFont = action.payload;
      } else {
        state.loginFont = initialStateValue.loginFont
      }
    },
    setLoginSelectedTemplate: (state, action) => {
      if (action.payload) {
        state.loginSelectedTemplate = action.payload;
      } else {
        state.loginSelectedTemplate = initialStateValue.loginSelectedTemplate
      }
    },
    setLoginSelectedTab: (state, action) => {
      if (action.payload) {
        state.loginSelectedTab = action.payload;
      } else {
        state.loginSelectedTab = initialStateValue.loginSelectedTab
      }
    },
    setLoginBrandColor: (state, action) => {
      if (action.payload) {
        state.loginBrandColor = action.payload;
      } else {
        state.loginBrandColor = initialStateValue.loginBrandColor
      }
    },
    setLoginTextColor: (state, action) => {
      if (action.payload) {
        state.loginTextColor = action.payload;
      } else {
        state.loginTextColor = initialStateValue.loginTextColor
      }
    },
    setLoginBackgroundColor: (state, action) => {
      if (action.payload) {
        state.loginBackgroundColor = action.payload;
      } else {
        state.loginBackgroundColor = initialStateValue.loginBackgroundColor
      }
    },
    setLoginCardColor: (state, action) => {
      if (action.payload) {
        state.loginCardColor = action.payload;
      } else {
        state.loginCardColor = initialStateValue.loginCardColor
      }
    },
    setLoginLinkColor: (state, action) => {
      if (action.payload) {
        state.loginLinkColor = action.payload;
      } else {
        state.loginLinkColor = initialStateValue.loginLinkColor
      }
    },
    setLoginInputBackgroundColor: (state, action) => {
      if (action.payload) {
        state.loginInputBackgroundColor = action.payload;
      } else {
        state.loginInputBackgroundColor = initialStateValue.loginInputBackgroundColor
      }
    },
    setLoginInputBorderColor: (state, action) => {
      if (action.payload) {
        state.loginInputBorderColor = action.payload;
      } else {
        state.loginInputBorderColor = initialStateValue.loginInputBorderColor
      }
    },
    setLoginButtonBackgroundColor: (state, action) => {
      if (action.payload) {
        state.loginButtonBackgroundColor = action.payload;
      } else {
        state.loginButtonBackgroundColor = initialStateValue.loginButtonBackgroundColor
      }
    },
    setLoginButtonTextColor: (state, action) => {
      if (action.payload) {
        state.loginButtonTextColor = action.payload;
      } else {
        state.loginButtonTextColor = initialStateValue.loginButtonTextColor
      }
    },
    setLoginSocialButtonLayout: (state, action) => {
      if (action.payload) {
        state.loginSocialButtonLayout = action.payload;
      } else {
        state.loginSocialButtonLayout = initialStateValue.loginSocialButtonLayout
      }
    },
    setLoginLanguage: (state, action) => {
      if (action.payload) {
        state.loginLanguage = action.payload;
      } else {
        state.loginLanguage = initialStateValue.loginLanguage
      }
    },
    setLoginTranslationData: (state, action) => {
      if (action.payload) {
        state.loginTranslationData = action.payload;
      } else {
        state.loginTranslationData = initialStateValue.loginTranslationData
      }
    },
    setLoginBackgroundImage: (state, action) => {
      if (action.payload) {
        state.loginBackgroundImage = action.payload;
      } else {
        state.loginBackgroundImage = initialStateValue.loginBackgroundImage
      }
    },
    setLoginTemplateContent: (state, action) => {
      if (action.payload) {
        state.loginTemplateContent = action.payload;
      } else {
        state.loginTemplateContent = initialStateValue.loginTemplateContent
      }
    },
  },
})

export const {
  resetLoginTemplateValues,
  setLoginFont,
  setLoginSelectedTemplate,
  setLoginSelectedTab,
  setLoginBrandColor,
  setLoginTextColor,
  setLoginBackgroundColor,
  setLoginCardColor,
  setLoginLinkColor,
  setLoginInputBackgroundColor,
  setLoginInputBorderColor,
  setLoginButtonBackgroundColor,
  setLoginButtonTextColor,
  setLoginSocialButtonLayout,
  setLoginLanguage,
  setLoginTranslationData,
  setLoginBackgroundImage,
  setLoginTemplateContent,
} = loginTemplateValuesSlice.actions

export default loginTemplateValuesSlice.reducer